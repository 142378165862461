import React, { useEffect, useState } from "react";
import KitIcon from '../Assets/ic_kit.png'
import Select from 'react-select'
import Form from 'react-bootstrap/Form';
import Header from '../Elements/Header.tsx';
import DatePicker from "react-datepicker";
import Button from 'react-bootstrap/Button';
import {Navigate} from "react-router-dom";
import {request} from '../Helpers/axios_helper';
import {TagStrings} from "../Elements/TagStrings.tsx";
import "../Styles/AddKit.css"
import "react-datepicker/dist/react-datepicker.css";

export class AddKit extends React.Component {
    alreadySubmitted = false
    companies
    manufacturedDate = null

    //Keep default values in sync with render()
    kitID = ""
    companyID = ""
    epc = ""
    parsedDate = ""
    expiry = "26"
    kitType = "0"
    beltRating = "0"
    beltRating1 = "1300"
    beltRating2 = "0"
    topCover = "0"
    bottomCover = "0"
    width = "33"
    
    constructor(props) {
        super(props);
        this.state = {loading: true, kitAdded: false, error: null, errorMsg: null};
        request(
            "GET",
            "/getAllCompanies",
            {
                
            }).then(
            (response) => {
                this.companies = response.data.companies
                this.setState({loading: false});
            }).catch(
            (error) => {
                console.log(error)
            });
    }

    onSetError = (errorMessage) => {
        this.setState({
            error:true,
            errorMsg:errorMessage});
        window.scrollTo(0, 0)
    }

    onChangeHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value.trim();
        console.log(name+": "+value)

        switch(name){
            case "company":
                this.companyID = value
                break;
            case "expiry":
                this.expiry = value
                break;
            case "kit":
                this.kitType = value
                break;
            case "beltRating":
                this.beltRating = value
                break;
            case "beltRating1":
                this.beltRating1 = value
                break;
            case "beltRating2":
                if(value.length == 0){
                    value = "0"
                }
                this.beltRating2 = value
                break;
            case "topCover":
                this.topCover = value
                break;
            case "bottomCover":
                this.bottomCover = value
                break;
            case "width":
                this.width = value
                break;
        }
    }

    onSetKitEPC = () =>{
        request(
            "POST",
            "/setKitEPC",
            {
                kitID: parseInt(this.kitID),
                epc: this.epc
            }).then(
            (response) => {
                this.setState({kitAdded: true});
            }).catch(
            (error) => {
                this.alreadySubmitted = false;
                console.log(error)
                this.setState({
                    error:true,
                    errorMsg:error.response.data["message"]});
                window.scrollTo(0, 0)
            }
        );
    }

    onCreateEPC = () => {
        request(
            "POST",
            "/createTag0",
            {
                universal: {
                    kitID: this.kitID,
                    tagType: 0,
                    date: parseInt(this.parsedDate),
                    expiry: parseInt(this.expiry)
                },
                kitType: parseInt(this.kitType),
                beltRatingType: parseInt(this.beltRating),
                beltRating1: parseInt(this.beltRating1),
                beltRating2: parseInt(this.beltRating2),
                topCover: parseInt(this.topCover),
                bottomCover: parseInt(this.bottomCover),
                width: parseInt(this.width)
            }).then(
            (response) => {
                this.epc = response.data.EPC
                this.onSetKitEPC()
            }).catch(
            (error) => {
                this.alreadySubmitted = false;
                console.log(error)
                this.setState({
                    error:true,
                    errorMsg:error.response.data["message"]});
                window.scrollTo(0, 0)
            }
        );
    }

    onCreateKit = () => {
        request(
            "POST",
            "/createKit",
            {
                companyID: parseInt(this.companyID),
            }).then(
            (response) => {
                this.kitID = response.data.id
                this.onCreateEPC()
            }).catch(
            (error) => {
                this.alreadySubmitted = false;
                console.log(error)
                this.setState({
                    error:true,
                    errorMsg:error.response.data["message"]});
                window.scrollTo(0, 0)
            }
        );
    }

    onVerifyEPCData = () => {
        request(
            "POST",
            "/createTag0",
            {
                universal: {
                    kitID: 1,
                    tagType: 0,
                    date: parseInt(this.parsedDate),
                    expiry: parseInt(this.expiry)
                },
                kitType: parseInt(this.kitType),
                beltRatingType: parseInt(this.beltRating),
                beltRating1: parseInt(this.beltRating1),
                beltRating2: parseInt(this.beltRating2),
                topCover: parseInt(this.topCover),
                bottomCover: parseInt(this.bottomCover),
                width: parseInt(this.width)
            }).then(
            (response) => {
                //EPC Data verified
                this.onCreateKit()
            }).catch(
            (error) => {
                this.alreadySubmitted = false;
                console.log(error)
                this.setState({
                    error:true,
                    errorMsg:error.response.data["message"]});
                window.scrollTo(0, 0)
            }
        );
    }

    onAddKit = (e) => {
        e.preventDefault();
        if(this.alreadySubmitted){
            return;
        }
        
        if(!this.beltRating1.endsWith("0")){
            this.onSetError("Belt rating 1 must be in increments of 10 and end with 0");
            return;
        }
        this.beltRating1 = this.beltRating1.slice(0, -1);

        if(!this.beltRating2.endsWith("0")){
            this.onSetError("Belt rating 2 must be in increments of 10 and end with 0");
            return;
        }
        
        if(this.beltRating2 != "0"){
            this.beltRating2 = this.beltRating2.slice(0, -1);
        }

        this.alreadySubmitted = true
        this.onVerifyEPCData()
    }
    
    render(){
        let {loading, kitAdded, error, errorMsg} = this.state
        var tagStrings = TagStrings.getTagStrings()

        const ManufacturedDate = () => {
            const [startDate, setStartDate] = useState(new Date());

            this.manufacturedDate = startDate
            var start = new Date(startDate.getFullYear(), 0, 0);
            var diff = (startDate - start) + ((start.getTimezoneOffset() - startDate.getTimezoneOffset()) * 60 * 1000);
            var oneDay = 1000 * 60 * 60 * 24;
            var day = String(Math.floor(diff / oneDay));
            while(day.length < 3){
                day = "0"+day
            }

            this.parsedDate = String(startDate.getFullYear())+String(day)
            return (
              <DatePicker
                inline
                selected={startDate}
                onChange={(date) => setStartDate(date)} />
            );
        };

        const Companies = (props) => {
            var options = []

            for (let i = 0; i < this.companies.length; i++) {
                const company = this.companies[i];
                if(i == 0){
                    this.companyID = company.id
                }

                options.push({ value: company.id, label: company.name });   
            }

            return <Select 
                        name="company"
                        defaultValue={options[0]}
                        placeholder={<div className="add-kit-select-placeholder">{props.placeholderText}</div>} 
                        className="add-kit-select-option" 
                        classNamePrefix="add-kit-selected-option"
                        onChange={(choice) => this.onChangeHandler({target:{name:"company",value:String(choice.value)}})}
                        options={options} />
        }

        const Kits = (props) => {
            var options = []

            for (let i = 0; i < tagStrings.kits.length; i++) {
                const kit = tagStrings.kits[i];
                options.push({ value: kit.id, label: kit.title+"\n"+kit.subtitle });   
            }

            return <Select 
                        name={props.name}
                        defaultValue={options[0]}
                        placeholder={<div className="add-kit-select-placeholder">{props.placeholderText}</div>} 
                        className="add-kit-select-option" 
                        classNamePrefix="lp-copy-sel"
                        onChange={(choice) => this.onChangeHandler({target:{name:props.name,value:String(choice.value)}})}
                        options={options} />
        }

        const CoverSizes = (props) => {
            var options = []

            for (let i = 0; i < tagStrings.cover_sizes.length; i++) {
                const coverSize = tagStrings.cover_sizes[i];
                options.push({ value: coverSize.id, label: coverSize.cover_size+'"' });   
            }

            return <Select 
                    name={props.name}
                    defaultValue={options[0]}
                    placeholder={<div className="add-kit-select-placeholder">{props.placeholderText}</div>} 
                    className="add-kit-select-option"
                    classNamePrefix="add-kit-selected-option"
                    onChange={(choice) => this.onChangeHandler({target:{name:props.name,value:String(choice.value)}})}
                    options={options} />
        }
    
        const BeltRating = (props) => {
            var options = []
            
            for (let i = 0; i < tagStrings.belt_ratings.length; i++) {
                const beltRating = tagStrings.belt_ratings[i];
                options.push({ value: beltRating.id, label: beltRating.rating });   
            }

            return <Select 
                    name={props.name}
                    defaultValue={options[0]}
                    placeholder={<div className="add-kit-select-placeholder">{props.placeholderText}</div>} 
                    className="add-kit-select-option"
                    classNamePrefix="add-kit-selected-option"
                    onChange={(choice) => this.onChangeHandler({target:{name:props.name,value:String(choice.value)}})}
                    options={options} />
        }

        return(
            <div>
                <Header backLink="kits" />

                {kitAdded && (
                    <Navigate to="/kits" replace={true} />
                )}

                {loading && (
                    <div id="loading-div">Loading...</div>
                )}

                {!loading && (
                    <center>
                    <Form id="add-kit-form" onSubmit={this.onAddKit}>
                        <center>
                            <img id="add-kit-logo" src={KitIcon} alt="logo"/>
                        </center>

                        <hr className="form-hr"></hr>

                        {error && 
                        <div id="error-alert" class="alert alert-danger mx-auto" role="alert">
                        <center><b>{errorMsg}</b></center>
                        </div>}

                        <div className="add-kit-form-label">Manufactured Date</div>
                        <ManufacturedDate />
                        <br />
                        <br />
                        <Form.Group className="mb-3">
                            <Form.Label className="add-kit-form-label">Expiry Weeks</Form.Label>
                            <Form.Control 
                                className="add-kit-form-input" 
                                onInput = {(e) =>{
                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,3)
                                }}
                                type="number" 
                                name="expiry" 
                                defaultValue={this.expiry} onChange={this.onChangeHandler}/>
                            <Form.Text className="text-muted">
                            The number of weeks after the manufactured date this kit expires.
                            </Form.Text>
                        </Form.Group>
                        <div className="add-kit-form-label">Company</div>
                        <Companies />
                        <br />
                        <div className="add-kit-form-label">Kit Type</div>
                        <Kits name={'kit'} placeholderText={'Select Kit Type'}/>
                        <br />
                        <div className="add-kit-form-label">Belt Rating Type</div>
                        <BeltRating name={'beltRating'} placeholderText={'Select Belt Rating'}/>
                        <br />
                        <Form.Group className="mb-3">
                            <Form.Label className="add-kit-form-label">Belt Rating 1</Form.Label>
                            <Form.Control 
                                className="add-kit-form-input" 
                                onInput = {(e) =>{
                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,5)
                                }}
                                type="number" 
                                name="beltRating1" 
                                defaultValue={this.beltRating1} onChange={this.onChangeHandler}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="add-kit-form-label">Belt Rating 2</Form.Label>
                            <Form.Control 
                                className="add-kit-form-input" 
                                onInput = {(e) =>{
                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,5)
                                }}
                                type="number" 
                                name="beltRating2"
                                onChange={this.onChangeHandler}/>
                            <Form.Text className="text-muted">
                            Leave blank if not required.
                            </Form.Text>
                        </Form.Group>
                        <div className="add-kit-form-label">Top Cover Size</div>
                        <CoverSizes name={'topCover'} placeholderText={'Select Top Cover Size'}/>
                        <br />
                        <div className="add-kit-form-label">Bottom Cover Size</div>
                        <CoverSizes name={'bottomCover'} placeholderText={'Select Bottom Cover Size'}/>
                        <br />
                        <Form.Group className="mb-3">
                            <Form.Label className="add-kit-form-label">Belt Width</Form.Label>
                            <Form.Control 
                                className="add-kit-form-input"
                                onInput = {(e) =>{
                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,3)
                                }}
                                type="number" 
                                name="width" 
                                defaultValue={this.width} onChange={this.onChangeHandler}/>
                            <Form.Text className="text-muted">
                            Belt width in inches.
                            </Form.Text>
                        </Form.Group>

                        <Button id="add-kit-btn" variant="primary" type="submit">
                            ADD
                        </Button>
                    </Form>
                    <br />
                    </center>
                )}
            </div>
        )
    }
}