import React from "react"
import {request, setAuthHeader} from '../Helpers/axios_helper.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DashboardItem from "../Elements/DashboardItem.tsx";
import {DashboardItemType} from "../Elements/DashboardItem.tsx";
import Header from '../Elements/Header.tsx';
import {TagStrings} from "../Elements/TagStrings.tsx";
import { UserInfo } from "../Elements/UserInfo.tsx";
import "../Styles/Dashboard.css"

export class Dashboard extends React.Component {
    userInfo = UserInfo.getUserInfo()

    constructor(props) {
        super(props);
        window.searchCache = null;
        TagStrings.checkForUpdate()
    }

    render() {
        let actuallyThis = this

        function GetDashboardItems(){
            if(actuallyThis.userInfo.admin){
                return <Row className="justify-content-center">
                    <DashboardItem type={DashboardItemType.KITS}/>
                    <DashboardItem type={DashboardItemType.IND_MATERIAL}/>
                    <DashboardItem type={DashboardItemType.COMPANIES}/>
                    <DashboardItem type={DashboardItemType.DECODE_EPC}/>
                    <DashboardItem type={DashboardItemType.COLDROOM_DEBUG}/>
                    <DashboardItem type={DashboardItemType.TAG_STRINGS}/>
                    <DashboardItem type={DashboardItemType.SEARCH}/>
                    <DashboardItem type={DashboardItemType.USERS}/>
                </Row>
            } else {
                return <Row className="justify-content-center">
                    <DashboardItem type={DashboardItemType.KITS}/>
                    <DashboardItem type={DashboardItemType.IND_MATERIAL}/>
                    <DashboardItem type={DashboardItemType.DECODE_EPC}/>
                    <DashboardItem type={DashboardItemType.SEARCH}/>
                </Row>
            }
        }

        return(
            <div>
                <Header />

                <Container id="location-button-container" fluid>
                        <GetDashboardItems />
                </Container>
            </div>

        )
    }
}