import React from "react"
import "../Styles/Register.css"
import {request, setAuthHeader} from '../Helpers/axios_helper';
import Logo from '../Assets/ColdRoomLogo.png'
import Logo2 from '../Assets/ColdRoomLogo_REV.png'
import Logo3 from '../Assets/AlmexFusion.png'
import {Navigate} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';

export class Register extends React.Component {
    state = {user: null, error: null, errorMsg: null};

    firstname = ""
    lastname = ""
    username = ""
    password = ""
    password2 = ""
    regcode = ""

    onChangeHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value.trim();

        switch(name){
            case 'firstName':
                this.firstname = value;
                break;
            case 'lastName':
                this.lastname = value;
                break;
            case 'userName':
                this.username = value;
                break;
            case 'password':
                this.password = value;
                break;
            case 'password2':
                this.password2 = value;
                break;
            case 'regCode':
                this.regcode = value;
                break;
        }
    };

    onRegister = (e) => {
        e.preventDefault();
        setAuthHeader(null);
        request(
            "POST",
            "/register",
            {
                firstName: this.firstname,
                lastName: this.lastname,
                login: this.username,
                password: this.password,
                regCode: this.regcode
            }).then(
            (response) => {
                setAuthHeader(response.data.token);
                let user = true;
                this.setState({user:true});
            }).catch(
            (error) => {
                console.log(error)
                setAuthHeader(null);
                this.setState({
                    error:true,
                    errorMsg:error.response.data["message"]});
                window.scrollTo(0, 0)
            }
        );
    };

    onSubmitRegister = (e) => {
        e.preventDefault();
        
        if(this.firstname.length == 0 ||
            this.lastname.length == 0 ||
            this.username.length == 0 ||
            this.password.length == 0 ||
            this.password2.length == 0 ||
            this.regcode.length == 0){
                this.setState({
                    error:true,
                    errorMsg:"All fields must not be empty"});
                window.scrollTo(0, 0)
                return;
            }

        if(this.password != this.password2){
            this.setState({
                error:true,
                errorMsg:"The passwords do not match"});
            window.scrollTo(0, 0)
            return;
        }

        this.onRegister(e);
    };

    render() {
        let { user, error, errorMsg } = this.state;
        return(
            <div id="login-root-div" style={{display:"flex", justifyContent:"space-between"}}>

                {user && (
                    <Navigate to="/" replace={true} />
                )}

                <Container id="login-container" fluid>
                    <Row>

                        <Col id="login-form-col" xs={12} sm={12} md={12} lg={6}>

                            <Form id="login-form" onSubmit={this.onSubmitRegister}>

                                <center>
                                    <img id="login-logo" src={Logo} alt="logo"/>
                                </center>

                                <hr className="form-hr"></hr>

                                {error && 
                                    <div id="error-alert" class="alert alert-danger mx-auto" role="alert">
                                    <center><b>{errorMsg}</b></center>
                                    </div>}

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="form-label">First Name</Form.Label>
                                    <Form.Control name="firstName" className="form-text-input" type="text" onChange={this.onChangeHandler} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="form-label">Last Name</Form.Label>
                                    <Form.Control name="lastName" className="form-text-input" type="text" onChange={this.onChangeHandler} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="form-label">Username</Form.Label>
                                    <Form.Control name="userName" className="form-text-input" type="text" onChange={this.onChangeHandler} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label className="form-label">Password</Form.Label>
                                    <Form.Control name="password" className="form-text-input" type="password" onChange={this.onChangeHandler} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label className="form-label">Password Again</Form.Label>
                                    <Form.Control name="password2" className="form-text-input" type="password" onChange={this.onChangeHandler} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="form-label">Registration Code</Form.Label>
                                    <Form.Control name="regCode" className="form-text-input" type="text" onChange={this.onChangeHandler} />
                                </Form.Group>

                                <center>
                                    <Button id="login-button" variant="primary" type="submit">
                                    REGISTER
                                    </Button>
                                </center>

                            </Form>
                        </Col>

                        <Col className="d-none d-lg-block">
                            <center>
                                <img id="login-side-logo" src={Logo2} width={750} alt="logo2" />
                            </center>
                        </Col>

                    </Row>
                    
                    <Row>

                        <Col>
                            <img id="fusion_logo" src={Logo3} alt="logo3"/>
                        </Col>

                    </Row>
                </Container>
            
            </div>
        )
    }
}
