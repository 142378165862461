import React from "react"
import {request} from '../Helpers/axios_helper';
import ArrayTable from "../Elements/ArrayTable.tsx";
import {ArrayTableMode} from "../Elements/ArrayTable.tsx";
import Header from '../Elements/Header.tsx';
import Modal from 'react-bootstrap/Modal';
import EPCTable from "../Elements/EPCTable.tsx";
import DebugIcon from '../Assets/ic_debug.png'
import Stack from 'react-bootstrap/Stack';
import {BrowserRouter as Router, Link} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import "../Styles/ColdroomDebug.css"

export class ColdroomDebug extends React.Component {
    coldroomEntries
    modalEPC;

    constructor(props) {
        super(props);
        this.state = {loading: true, modal: false};
        request(
            "GET",
            "/getAllColdroomEntries",
            {
                
            }).then(
            (response) => {
                this.coldroomEntries = response.data.entries
                this.setState({loading: false});
            }).catch(
            (error) => {
                console.log(error)
            });
    }

    onShowEPCModal = (epc) => {
        this.modalEPC = epc
        this.setState({modal: true});
    }

    onHideEPCModal = () => {
        this.setState({modal: false});
    }

    render() {
        let { loading, modal } = this.state
        let modelEPC = this.modalEPC

        return(
            <div>
                <Header backLink="dashboard"/>

                {loading && (
                    <div id="loading-div">Loading...</div>
                )}

                {!loading && (
                    <div id="content">
                        <center>
                            <img id="debug-icon" src={DebugIcon} />
                            <br />
                            <span id="debug-title" >Coldroom Entries</span>
                        </center>
                        <ArrayTable 
                            array={this.coldroomEntries}
                            mode={ArrayTableMode.COLDROOM_DEBUG} 
                            showModalFunc={this.onShowEPCModal} />
                        <Stack>
                            <Link to="/addColdroomEntry" className="p-2 ms-auto">
                                <Button id="add-new-entry-btn" className="ms-auto" variant="primary"><b>Add New</b></Button>
                            </Link>
                        </Stack>
                    </div>
                )}

                {modal && (
                    <Modal show={modal} onHide={this.onHideEPCModal} size="lg">
                        <Modal.Header closeButton>
                        <Modal.Title>EPC</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <EPCTable epc={modelEPC} />
                        </Modal.Body>
                    </Modal>
                )}
            </div>
        )
    }
}