import React from "react";
import { request,productionRequest } from '../Helpers/axios_helper.js';
import ArrayTable from "../Elements/ArrayTable.tsx";
import { ArrayTableMode } from "../Elements/ArrayTable.tsx";
import Header from '../Elements/Header.tsx';
import Button from 'react-bootstrap/Button';
import "../Styles/ColdroomDebug.css";
import "../Styles/Search.css"
import SearchIcon from '../Assets/ic_search.png';
import {TagStrings} from "../Elements/TagStrings.tsx";
import Form from 'react-bootstrap/Form';

export class Search extends React.Component {
    state = {
        queryGiven: false, 
        lastUpdate: 0, 
        matchedTags0: [],
        matchedTags1: [],
        matchedTags2: [],
        complete: false,
        percent:0};
    companies;
    currentPage = 0;
    numOfPages = 1;
    searchQuery = "";

    constructor(props) {
        super(props);
        TagStrings.checkForUpdate();
        if(window.searchCache != null){
            this.reset();
            this.searchQuery = window.searchCache;
            this.state.queryGiven = true;
            this.onGetCompanies();
        }
    }

    reset = () => {
        this.currentPage = 0;
        this.state.complete = false;
        this.state.percent = 0;
        this.state.matchedTags0 = [];
        this.state.matchedTags1 = [];
        this.state.matchedTags2 = [];
        this.searchQuery = "";
    }

    onInitCache = () => {
        if(window.searchCache == null){
            window.searchCache = {};
        }
    }

    onChangeHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value.trim();
        switch(name){
            case 'query':
                this.searchQuery = value;
                break;
        }
    }

    onHandleSubmit = (e) => {
        e.preventDefault();

        if(this.searchQuery.trim().length == 0){
            return
        }

        window.searchCache = this.searchQuery.trim();
        this.setState({queryGiven:true})
        this.onGetCompanies();
    }

    onBackPushed = () => {
        window.searchCache = null;
        this.reset();
        this.setState({queryGiven:false})
    }

    onGetCompanies = () => {
        this.setState({percent:1})
        request(
            "GET",
            "/getAllCompanies",
            {
                
            }).then(
            (response) => {
                this.companies = response.data.companies
                this.onGetNewestColdroomEntries();
            }).catch(
            (error) => {
                console.log(error)
            });
    }

    onGetCompanyName = (companyID) => {
        for (let index = 0; index < this.companies.length; index++) {
            const company = this.companies[index]
            if(company.id == companyID){
                return company.name
            }
        }
        return String(companyID)
    }

    // 1. Get unique coldroom entries 1000 at a time
    onGetNewestColdroomEntries = () => {
        if(!window.location.href.includes("search")){
            return;
        }

        request(
            "POST",
            "/getAllTags",
            {
                page: this.currentPage,
                pageSize: 1000
            }).then(
                (response) => {
                    if(!window.location.href.includes("search")){
                        return;
                    }

                    this.decodePageResults(response.data)
                    this.numOfPages = response.data.num_of_pages;
                    var currentPercent = Math.ceil((parseFloat(this.currentPage)/parseFloat(this.numOfPages))*100.0);
                    if(currentPercent < 1){
                        currentPercent = 1;
                    }
                    this.setState({percent:currentPercent})
                    
                    if(this.numOfPages > this.currentPage){
                        this.currentPage  += 1
                        this.onGetNewestColdroomEntries();
                    }
                }).catch(
                (error) => {
                    console.log(error);
                });
    }

    // 2. Decode the epc's
    decodePageResults = (data) => {
        var epcList = [];
        data.kits.map((value, index) => {
            epcList.push(value.epc);
        })

        data.kit_materials.map((value, index) => {
            epcList.push(value.epc);
        })

        if(epcList.length == 0){
            return;
        }

        request(
            "POST",
            "/decodeMultipleEpc",
            {
                epcList: epcList,
                companyKey: "VU8UC6",
                lang: "en"
            }).then(
                (response) => {
                    var epc = "";
                    for (const [key, value] of Object.entries(response.data.epcList)) {
                        data.kits.map((evalue, eindex) => {
                            if(evalue.epc == value.epc){
                                response.data.epcList[key].table_data = data.kits[eindex];
                            }
                        })

                        data.kit_materials.map((evalue, eindex) => {
                            if(evalue.epc == value.epc){
                                response.data.epcList[key].table_data = data.kit_materials[eindex];
                            }
                        })
                    }
                    this.addSearchStrings(response.data.epcList)
                }).catch(
                (error) => {
                    console.log(error);
                });
    }

    // 3. Add search strings for each tag
    addSearchStrings = (epcList) => {
        for (const [key, value] of Object.entries(epcList)) {
            switch(value.type){
                case 0:
                    epcList[key].searchStrings = this.getTag0SearchStrings(value);
                    break;
                case 1:
                    epcList[key].searchStrings = this.getTag1SearchStrings(value);
                    break;
                case 2:
                    epcList[key].searchStrings = this.getTag2SearchStrings(value);
                    break;
            }
        }

        this.searchPageResults(epcList);
    }

    getTag0SearchStrings = (epcData) => {
        var searchStrings = [];

        searchStrings.push(
            epcData.values.title
        );

        searchStrings.push(
            epcData.values.subtitle
        );

        searchStrings.push(
            epcData.epc.slice(0,2)+"-"+epcData.values.kit_id
        );

        searchStrings.push(
            epcData.values.top_cover
        );

        searchStrings.push(
            epcData.values.bottom_cover
        );

        searchStrings.push(
            epcData.values.width
        );

        searchStrings.push(
            epcData.values.belt_rating
        );

        searchStrings.push(
            epcData.epc
        );

        return searchStrings;
    }

    getTag1SearchStrings = (epcData) => {
        var searchStrings = [];

        searchStrings.push(
            epcData.values.material_num
        );

        searchStrings.push(
            epcData.values.material_desc
        );

        searchStrings.push(
            epcData.table_data.lotNum
        );

        searchStrings.push(
            String(epcData.values.kit_id)
        );

        searchStrings.push(
            epcData.epc.slice(0,2)+"-"+epcData.tag_values.material_num+"-"+epcData.values.material_id
        );

        searchStrings.push(
            epcData.epc
        );

        return searchStrings;
    }

    getTag2SearchStrings = (epcData) => {
        var searchStrings = [];
        var thirdPartyData = JSON.parse(atob(epcData.data))

        searchStrings.push(
            epcData.epc.slice(0,2)+"-"+epcData.values.kit_id
        );

        searchStrings.push(
            epcData.epc
        );

        for (const [key, value] of Object.entries(thirdPartyData)) {
            searchStrings.push(
                value
            );
        }

        return searchStrings;
    }

    // 4. Search the search strings and populate matches
    searchPageResults = (epcList) => {
        var searchString = "";
        var searchQuery = this.searchQuery.trim().toLowerCase();

        for (const [key, value] of Object.entries(epcList)) {
            var matched = false
            for (let i = 0; i < value.searchStrings.length; i++) {
                if(matched){
                    break;
                }

                if(value.searchStrings[i] == null){
                    continue;
                }

                searchString = value.searchStrings[i].trim().toLowerCase();
                if(searchString.includes(searchQuery)){
                    matched = true
                    switch (value.type) {
                        case 0:
                            this.state.matchedTags0.push(value.table_data);
                            break;
                        case 1:
                            this.state.matchedTags1.push(value.table_data);
                            break;
                        case 2:
                            this.state.matchedTags2.push(value.table_data);
                            break;
                    }
                }
            }
        }

        if(this.numOfPages == this.currentPage){
            this.setState({complete:true})
        }
        
        this.setState({ loading:false, lastUpdate: Date.now() });
    }

    render() {
        let actuallyThis = this
        const { queryGiven, lastUpdate, percent, complete} = this.state;

        function ArrayTable0(){
            if(actuallyThis.state.matchedTags0 == null ||
                actuallyThis.state.matchedTags0.length == 0){
                    return null;
            }

            return <div>
                <div className="search-sub-title">Kits</div>
                <ArrayTable
                    array={actuallyThis.state.matchedTags0}
                    mode={ArrayTableMode.KITS}
                    getCompanyNameFunc={actuallyThis.onGetCompanyName}
                    errorMsg={"No kits found"}
                    backLink={"search"} />
            </div>
        }

        function ArrayTable1(){
            if(actuallyThis.state.matchedTags1 == null ||
                actuallyThis.state.matchedTags1.length == 0){
                    return null;
            }

            return <div>
                <div className="search-sub-title">Kit Materials</div>
                <ArrayTable
                            array={actuallyThis.state.matchedTags1}
                            mode={ArrayTableMode.KIT_MATERIAL}
                            getCompanyNameFunc={actuallyThis.onGetCompanyName}
                            errorMsg={"No kit materials found"}
                            backLink={"search"} />
            </div>
        }

        function ArrayTable2(){
            if(actuallyThis.state.matchedTags2 == null ||
                actuallyThis.state.matchedTags2.length == 0){
                    return null;
            }

            return <div>
                <div className="search-sub-title">Independent Materials</div>
                <ArrayTable
                            array={actuallyThis.state.matchedTags2}
                            mode={ArrayTableMode.IND_MATERIAL}
                            getCompanyNameFunc={actuallyThis.onGetCompanyName}
                            errorMsg={"No independent materials found"}
                            backLink={"search"} />
            </div>
        }

        function NoMatchWarning(){
            if(actuallyThis.state.matchedTags0.length == 0 && 
                actuallyThis.state.matchedTags1.length == 0 &&
                actuallyThis.state.matchedTags2.length == 0){
                    return <div id="error-alert" className="alert alert-danger mx-auto" role="alert">
                    <center><b>{"No matches"}</b></center>
                    </div>
                } else {
                    return null;
                }
        }

        return (
            <div>
                {!queryGiven && (
                    <Header backLink="dashboard" />
                )}

                {queryGiven && (
                    <Header overRide={this.onBackPushed} />
                )}

                {!queryGiven && (
                    <center>
                        <Form id="search-form" onSubmit={this.onHandleSubmit}>
        
                            <center>
                                <img id="search-logo" src={SearchIcon} alt="logo"/>
                            </center>
        
                            <hr className="form-hr"></hr>
        
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label className="search-form-label">Search:</Form.Label>
                                <Form.Control name="query" className="search-form-input" type="text" onChange={this.onChangeHandler} />
                            </Form.Group>
        
                            <center>
                                <Button id="search-btn" variant="primary" type="submit">
                                Search
                                </Button>
                            </center>
        
                        </Form>
                        <br />
                    </center>
                )}

                {queryGiven && (
                    <div id="content">
                        <center>
                            <img id="search-icon" src={SearchIcon} />
                            <br />
                            <div id="search-title">"{actuallyThis.searchQuery}"</div>
                            <div id="search-title">Searching {percent}%</div>
                        </center>
                        <br />

                        {complete && (
                            <NoMatchWarning />
                        )}

                        <ArrayTable0 />
                        <ArrayTable1 />
                        <ArrayTable2 />

                    </div>
                )}

                <br />
            </div>
        );
    }
}
