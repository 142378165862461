import React from "react"
import {request} from '../Helpers/axios_helper.js';
import ArrayTable from "../Elements/ArrayTable.tsx";
import {ArrayTableMode} from "../Elements/ArrayTable.tsx";
import Header from '../Elements/Header.tsx';
import KitsIcon from '../Assets/ic_kit.png'
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import {TagStrings} from "../Elements/TagStrings.tsx";
import {
    BrowserRouter as Router,
    Link
  } from "react-router-dom";
import '../Styles/Kits.css'

export class Kits extends React.Component {
    state = {
        loading: true,
        page: 0
    }
    companies;
    kits;
    numOfPages = 1;

    constructor(props) {
        super(props);
        TagStrings.checkForUpdate()
    }

    componentDidMount(){
        this.onGetKits()
    }

    onGetKits = () => {
        request(
            "POST",
            "/getAlmexKits",
            {
                page: this.state.page,
                pageSize: 10
            }).then(
            (response) => {
                this.kits = response.data.kits;
                this.numOfPages = response.data.num_of_pages;
                this.onGetCompanies()
            }).catch(
            (error) => {
                console.log(error)
            });
    }

    onGetCompanies = () => {
        request(
            "GET",
            "/getAllCompanies",
            {
                
            }).then(
            (response) => {
                this.companies = response.data.companies
                this.setState({loading: false});
            }).catch(
            (error) => {
                console.log(error)
            });
    }

    onGetCompanyName = (companyID) => {
        for (let index = 0; index < this.companies.length; index++) {
            const company = this.companies[index]
            if(company.id == companyID){
                return company.name
            }
        }
        return String(companyID)
    }

    goToPreviousPage = () => {
        if(this.state.page == 0){
            return;
        }

        this.state.page = this.state.page-1
        this.kits = []
        this.setState({ loading: true});
        this.onGetKits()
    }

    goToNextPage = () => {
        if(this.state.page+1 >= this.numOfPages){
            return;
        }

        this.state.page = this.state.page+1
        this.kits = []
        this.setState({ loading: true});
        this.onGetKits()
    }

    render() {
        let { loading } = this.state

        return(
            <div>
                <Header backLink="dashboard" />
                {loading && (
                    <div id="loading-div">Loading...</div>
                )}

                {!loading && (
                    <div id="content">
                        <center>
                            <img id="kits-icon" src={KitsIcon} />
                            <br />
                            <div id="kits-title" >Almex Kits</div>
                            <div id="kits-page">Page #{this.state.page+1}/{this.numOfPages}</div>
                        </center>
                        
                        <ArrayTable 
                            array={this.kits} 
                            mode={ArrayTableMode.KITS} 
                            showModalFunc={this.onShowEPCModal}
                            backLink={"kits"}
                            getCompanyNameFunc={this.onGetCompanyName} />
                        
                        <Stack direction="horizontal" gap={3}>
                            <Button
                                onClick={() => this.goToPreviousPage()}
                                className="page-button p-2">
                                    Previous
                            </Button>
                            <Button
                                onClick={() => this.goToNextPage()}
                                className="page-button p-2">
                                    Next
                            </Button>
                            <Link to="/addKit" className="p-2 ms-auto">
                                <Button id="add-new-kit-btn" className="ms-auto" variant="primary"><b>Add New</b></Button>
                            </Link>
                        </Stack>
                    </div>
                )}

                <br />
            </div>
        )
    }
}