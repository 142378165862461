import React, { useEffect, useState } from "react";
import { useSearchParams, Link, Navigate } from 'react-router-dom';
import MaterialIcon from '../Assets/ic_material.png'
import Select from 'react-select'
import Form from 'react-bootstrap/Form';
import Header from '../Elements/Header.tsx';
import DatePicker from "react-datepicker";
import Button from 'react-bootstrap/Button';
import {request} from '../Helpers/axios_helper';
import {TagStrings} from "../Elements/TagStrings.tsx";
import "../Styles/AddKitMaterial.css"
import "react-datepicker/dist/react-datepicker.css";

export class AddKitMaterial extends React.Component {
    alreadySubmitted = false
    manufacturedDate = null
    kitID
    parsedDate = ""
    expiry = "26"
    materialNum = "0"
    lotNum = ""
    epc = ""

    constructor(props) {
        super(props);
        this.state = {loaded: false, materialAdded: false, error: null, errorMsg: null};
    }

    onSetError = (errorMessage) => {
        this.setState({
            error:true,
            errorMsg:errorMessage});
        window.scrollTo(0, 0)
    }

    onAddMaterialToKit = () => {
        request(
            "POST",
            "/addKitMaterial",
            {
                kitID: parseInt(this.kitID),
                epc: this.epc,
                lotNum: this.lotNum
            }).then(
            (response) => {
                this.setState({materialAdded: true});
            }).catch(
            (error) => {
                this.alreadySubmitted = false;
                console.log(error)
                this.onSetError(error.response.data["message"])
            }
        );
    }

    onCreateEPC = () => {
        request(
            "POST",
            "/createTag1",
            {
                universal: {
                    kitID: parseInt(this.kitID),
                    tagType: 1,
                    date: parseInt(this.parsedDate),
                    expiry: parseInt(this.expiry)
                },
                materialNum: parseInt(this.materialNum)
            }).then(
            (response) => {
                //EPC Data verified
                this.epc = response.data.EPC
                this.onAddMaterialToKit()
            }).catch(
            (error) => {
                this.alreadySubmitted = false;
                console.log(error)
                this.onSetError(error.response.data["message"])
            }
        );
    }

    onChangeHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value.trim();

        switch(name){
            case "expiry":
                this.expiry = value;
                break;
            case "materialNum":
                this.materialNum = value;
                break;
            case "lotNum":
                this.lotNum = value;
                break;
        }
    }

    onSubmit = (e) => {
        e.preventDefault();

        if(this.kitID == null){
            this.onSetError("Kit ID not set")
            return;
        }

        if(this.lotNum.trim().length == 0){
            this.onSetError("Lot number not set")
            return;
        }

        if(this.alreadySubmitted){
            return;
        }

        this.setState({error:false})
        this.alreadySubmitted = true
        this.onCreateEPC()
    }

    render(){
        let actuallyThis = this
        let {loaded, materialAdded, error, errorMsg} = this.state
        var tagStrings = TagStrings.getTagStrings()

        function GetKitID(){
            const [searchParams, setSearchParams] = useSearchParams();
            actuallyThis.kitID = searchParams.get("kit")
            actuallyThis.setState({loaded:true})
        }

        const ManufacturedDate = () => {
            const [startDate, setStartDate] = useState(new Date());

            this.manufacturedDate = startDate
            var start = new Date(startDate.getFullYear(), 0, 0);
            var diff = (startDate - start) + ((start.getTimezoneOffset() - startDate.getTimezoneOffset()) * 60 * 1000);
            var oneDay = 1000 * 60 * 60 * 24;
            var day = String(Math.floor(diff / oneDay));
            while(day.length < 3){
                day = "0"+day
            }

            this.parsedDate = String(startDate.getFullYear())+String(day)
            return (
              <DatePicker
                inline
                selected={startDate}
                onChange={(date) => setStartDate(date)} />
            );
        };

        const Materials = (props) => {
            var options = []
            for (let i = 0; i < tagStrings.materials.length; i++) {
                const material = tagStrings.materials[i];
                options.push({ value: material.id, label: material.part_number+"\n"+material.part_desc });   
            }

            return <Select 
                        name={props.name}
                        defaultValue={options[0]}
                        placeholder={<div className="add-kit-material-select-placeholder">{props.placeholderText}</div>} 
                        className="add-kit-material-select-option" 
                        classNamePrefix="lp-copy-sel"
                        onChange={(choice) => this.onChangeHandler({target:{name:props.name,value:String(choice.value)}})}
                        options={options} />
        }

        return(
            <div>
                {!loaded && (
                    <GetKitID />
                )}

                {loaded && (
                    <Header backLink={"kitMaterial?kit="+actuallyThis.kitID} />
                )}

                {loaded && materialAdded && (
                    <Navigate to={"/kitMaterial?kit="+actuallyThis.kitID} replace={true} />
                )}

                {loaded && !materialAdded && (
                    <center>
                        <Form id="add-kit-material-form" onSubmit={this.onSubmit}>
                            <center>
                                <img id="add-kit-material-logo" src={MaterialIcon} alt="logo"/>
                            </center>

                            <hr className="form-hr"></hr>

                            {error && 
                            <div id="error-alert" className="alert alert-danger mx-auto" role="alert">
                            <center><b>{errorMsg}</b></center>
                            </div>}

                            <div className="add-kit-material-form-label">Manufactured Date</div>
                            <ManufacturedDate />
                            <br />
                            <br />
                            <Form.Group className="mb-3">
                                <Form.Label className="add-kit-material-form-label">Expiry Weeks</Form.Label>
                                <Form.Control 
                                    className="add-kit-material-form-input" 
                                    onInput = {(e) =>{
                                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,3)
                                    }}
                                    type="number" 
                                    name="expiry" 
                                    defaultValue={this.expiry} onChange={this.onChangeHandler}/>
                                <Form.Text className="text-muted">
                                The number of weeks after the manufactured date this material expires.
                                </Form.Text>
                            </Form.Group>

                            <div className="add-kit-material-form-label">Material</div>
                            <Materials name={'materialNum'} />
                            <br />
                            <Form.Group className="mb-3">
                                <Form.Label className="add-kit-material-form-label">Lot Number</Form.Label>
                                <Form.Control 
                                    className="add-kit-material-form-input"
                                    maxLength={100}
                                    type="text" 
                                    name="lotNum" 
                                    onChange={this.onChangeHandler}/>
                                <Form.Text className="text-muted">
                                The lot number of this material from Epicor.
                                </Form.Text>
                            </Form.Group>

                            <Button id="add-kit-material-btn" variant="primary" type="submit">
                                ADD
                            </Button>
                        </Form>
                        <br />
                    </center>
                )}

            </div>
        )
    }
}