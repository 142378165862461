import './App.css';
import {Routes, Route} from 'react-router-dom'
import Login from './Components/Pages/Login.jsx'
import {useNavigate } from "react-router-dom";
import {Dashboard} from './Components/Pages/Dashboard.tsx'
import {EditTagStrings} from './Components/Pages/EditTagStrings.jsx'
import {Register} from './Components/Pages/Register.jsx'
import {Companies} from './Components/Pages/Companies.jsx'
import {AddCompany} from './Components/Pages/AddCompany.jsx'
import {Decode} from './Components/Pages/Decode.jsx'
import {Kits} from './Components/Pages/Kits.jsx'
import {IndependentMaterial} from './Components/Pages/IndependentMaterial.jsx';
import {AddKit} from './Components/Pages/AddKit.jsx'
import {AddIndependentMaterial} from './Components/Pages/AddIndependentMaterial.jsx';
import {DeleteKit} from './Components/Pages/DeleteKit.jsx'
import {ShipKit} from './Components/Pages/ShipKit.jsx'
import {KitMaterial} from './Components/Pages/KitMaterial.jsx'
import {AddKitMaterial} from './Components/Pages/AddKitMaterial.jsx'
import {TagStringDash} from './Components/Pages/TagStringsDash.jsx'
import {AddTagString} from './Components/Pages/AddTagString.jsx'
import {ColdroomDebug} from './Components/Pages/ColdroomDebug.jsx'
import {AddColdroomEntry} from './Components/Pages/AddColdroomEntry.jsx'
import {Search} from './Components/Pages/Search.jsx'
import {Users} from './Components/Pages/Users.jsx'

function App() {
  return (
    <>
      <Routes>
        <Route path ="/" element={<Login/>}/>
        <Route path ="/register" element={<Register/>}/>
        <Route path ="/dashboard" element={<Dashboard/>}/>
        <Route path ="/companies" element={<Companies/>}/>
        <Route path ="/coldroomDebug" element={<ColdroomDebug/>}/>
        <Route path ="/addColdroomEntry" element={<AddColdroomEntry/>}/>
        <Route path ="/addCompany" element={<AddCompany/>}/>
        <Route path ="/decode" element={<Decode/>}/>
        <Route path ="/kits" element={<Kits/>}/>
        <Route path ="/independentMaterial" element={<IndependentMaterial/>}/>
        <Route path ="/addKit" element={<AddKit/>}/>
        <Route path ="/addIndependentMaterial" element={<AddIndependentMaterial/>}/>
        <Route path ="/deleteKit" element={<DeleteKit/>}/>
        <Route path ="/shipKit" element={<ShipKit/>}/>
        <Route path ="/kitMaterial" element={<KitMaterial/>}/>
        <Route path ="/addKitMaterial" element={<AddKitMaterial/>}/>
        <Route path ="/tagStringDash" element={<TagStringDash/>}/>
        <Route path ="/addTagString" element={<AddTagString/>}/>
        <Route path ="/editTagStrings" element={<EditTagStrings/>}/>
        <Route path ="/users" element={<Users/>}/>
        <Route path ="/search" element={<Search/>}/>
      </Routes>
    </>
  );
}

export default App;