import React from "react"
import DecodeIcon from '../Assets/ic_decode.png'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Header from '../Elements/Header.tsx';
import {request} from '../Helpers/axios_helper';
import {Navigate} from "react-router-dom";
import '../Styles/Decode.css'
import EPCTable from "../Elements/EPCTable.tsx";

export class Decode extends React.Component {
    state = {epcSet: false, error: null, errorMsg: null};
    epc = ""

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        window.loadEPC = this.loadEPC.bind(this);
    }

    onSetError = (errorMessage) => {
        this.setState({
            error:true,
            errorMsg:errorMessage});
        window.scrollTo(0, 0)
    }

    onChangeHandler = (event) => {
        this.setState({epcSet:false,error:false});
        let name = event.target.name;
        let value = event.target.value.trim();

        switch(name){
            case 'epc':
                this.epc = value;
                break;
        }
    }

    onDecodeEPC = (e) => {
        e.preventDefault();

        if(this.epc.length < 32){
            this.onSetError("Invalid EPC length")
            return;
        }

        this.setState({epcSet:true});
    }

    onReadEPC = () => {
        if(window.AndroidReadEPC){
            window.AndroidReadEPC.start();
        }
    }

    //Called from Android
    loadEPC = (epc) => {
        this.epc = epc
        this.setState({epcSet:true});
    }

    render(){
        let actuallyThis = this
        let { epcSet, error, errorMsg } = this.state;

        function ReadTagBtn(){
            if (window.AndroidReadEPC){
                return <Button 
                    className="decode-read-button" 
                    variant="primary"
                    onClick={() => actuallyThis.onReadEPC()}>
                        <b>READ TAG</b>
                </Button>
            } else {
                return null
            }
        }

        return(
            <div>
                <Header backLink="dashboard" />

                <center>
                <Form id="decode-form" onSubmit={this.onDecodeEPC}>

                    <center>
                        <img id="decode-logo" src={DecodeIcon} alt="logo"/>
                    </center>

                    <hr className="form-hr"></hr>

                    {error && 
                        <div id="error-alert" className="alert alert-danger mx-auto" role="alert">
                        <center><b>{errorMsg}</b></center>
                        </div>}

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="decode-form-label">EPC</Form.Label>
                        <Form.Control name="epc" className="decode-form-input" type="text" onChange={this.onChangeHandler} />
                    </Form.Group>

                    <center>
                        <Button id="decode-button" variant="primary" type="submit">
                        DECODE
                        </Button>
                        <br/>
                        <ReadTagBtn />
                    </center>

                </Form>
                <br />

                {epcSet && (
                    <div id="decode-epc-table-container">
                        <EPCTable epc={this.epc} />
                        <br />
                    </div>
                )}

                </center>
            </div>
        )
    }
}