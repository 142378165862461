import React from 'react'
import Container from 'react-bootstrap/Container';
import ColdRoomLogo from '../Assets/ColdRoomLogo_REV.png'
import BackIcon from '../Assets/ic_back.png'
import LogoutIcon from '../Assets/ic_logout.png'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import LoginCheck from "./LoginCheck.js";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../Styles/Header.css"


export default class Header extends React.Component {
  backLink = null
  overRide

  constructor(props) {
    super(props);
    this.backLink = props.backLink
    this.overRide = props.overRide
  }

  render() {
    var actuallyThis = this

    function BackButton(){
      if(actuallyThis.overRide != null){
        return <Button variant="primary" id="header-btn" onClick={() => actuallyThis.overRide()}>
               <center><img id="header-icon" src={BackIcon} /></center>
               </Button>
      }
      
      if(actuallyThis.backLink == null){
        return <div>&nbsp;</div>
      } else {
        return <Link to={"/"+actuallyThis.backLink}>
               <Button variant="primary" id="header-btn">
               <center><img id="header-icon" src={BackIcon} /></center>
               </Button>
               </Link>
      }
    }

    return (
      <div>
        <LoginCheck />
        <Container id="logo-header-container" fluid>
              <Row>
                  <Col>
                    <center>
                      <BackButton />
                    </center>
                  </Col>
                  <Col>
                    <Link to="/dashboard">
                      <center><img id="header-logo" src={ColdRoomLogo} alt="logo4" /></center>
                    </Link>
                  </Col>
                  <Col>
                    <Link to="/">
                      <center>
                        <Button variant="primary" id="header-btn">
                        <center><img id="header-icon" src={LogoutIcon} /></center>
                        </Button>
                      </center>
                    </Link>
                  </Col>
              </Row>
          </Container>

      </div>
    )
  }
}