import React from "react"
import DebugIcon from '../Assets/ic_debug.png'
import Header from '../Elements/Header.tsx';
import {request} from '../Helpers/axios_helper';
import {Navigate} from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import "../Styles/AddColdroomEntry.css"

export class AddColdroomEntry extends React.Component {
    state = {complete: false, error: null, errorMsg: null};
    epc
    status = "1"

    constructor(props) {
        super(props);
    }

    onSetError = (errorMessage) => {
        this.setState({
            error:true,
            errorMsg:errorMessage});
        window.scrollTo(0, 0)
    }

    onChangeHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value.trim();

        switch(name){
            case 'epc':
                this.epc = value;
                break;
            case 'status':
                this.status = value;
                break;
        }
    }

    onAddEntry = (e) => {
        e.preventDefault();
        request(
            "POST",
            "/createColdroomEntry",
            {
                tag: this.epc,
                status: parseInt(this.status),
                location: "Stoney Creek"
            }).then(
            (response) => {
                this.setState({
                    complete:true});
            }).catch(
            (error) => {
                console.log(error)
                this.setState({
                    error:true,
                    errorMsg:error.response.data["message"]});
                window.scrollTo(0, 0)
            }
        );
    }

    render() {
        let { complete, error, errorMsg } = this.state;

        return (
            <div>
                <Header backLink="coldroomDebug" />

                {complete && (
                    <Navigate to="/coldroomDebug" replace={true} />
                )}

                <center>
                <Form id="add-entry-form" onSubmit={this.onAddEntry}>

                    <center>
                        <img id="add-entry-logo" src={DebugIcon} alt="logo"/>
                    </center>

                    <hr className="form-hr"></hr>

                    {error && 
                        <div id="error-alert" class="alert alert-danger mx-auto" role="alert">
                        <center><b>{errorMsg}</b></center>
                        </div>}

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="add-entry-form-label">EPC</Form.Label>
                        <Form.Control name="epc" className="add-entry-form-input" type="text" onChange={this.onChangeHandler} />
                    </Form.Group>

                    <Form.Label className="add-entry-form-label">Status</Form.Label>
                    <Form.Select id="entry-status-select" size="lg" name="status" onChange={this.onChangeHandler}>
                        <option value="1">IN</option>
                        <option value="0">OUT</option>
                    </Form.Select>

                    <center>
                        <Button id="add-entry-btn" variant="primary" type="submit">
                        ADD
                        </Button>
                    </center>

                </Form>
                </center>
            </div>
        )
    }
}