import axios from 'axios';

export const getLoginTime = () => {
  return window.localStorage.getItem('login_time');
}

export const setLoginTime = () => {
  window.localStorage.setItem("login_time", Date.now());
}

export const getAuthToken = () => {
    return window.localStorage.getItem('auth_token');
};

export const setAuthHeader = (token) => {
    if (token !== null) {
      window.localStorage.setItem("auth_token", token);
    } else {
      window.localStorage.removeItem("auth_token");
    }
};

//Debug Locally
//axios.defaults.baseURL = 'http://localhost:8080'; 

axios.defaults.baseURL = 'https://coldroom.almex.com:8080';
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const request = (method, url, data) => {

    let headers = {};
    if (getAuthToken() !== null && getAuthToken() !== "null") {
        headers = {'Authorization': `Bearer ${getAuthToken()}`};
    }

    return axios({
        method: method,
        url: url,
        headers: headers,
        data: data});
};