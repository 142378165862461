import React from 'react'
import Container from 'react-bootstrap/Container';
import DecodeEpcIcon from '../Assets/ic_decode.png'
import DebugIcon from '../Assets/ic_debug.png'
import CompanyIcon from '../Assets/ic_company.png'
import KitIcon from '../Assets/ic_kit.png'
import MaterialIcon from '../Assets/ic_material.png'
import TagIcon from '../Assets/ic_tag.png'
import UsersIcon from '../Assets/ic_users.png'
import SearchIcon from '../Assets/ic_search.png'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
    BrowserRouter as Router,
    Route,
    Link,
    useParams
  } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import "../Styles/DashboardItem.css"

export enum DashboardItemType{
  DECODE_EPC = 0,
  COLDROOM_DEBUG = 1,
  COMPANIES = 2,
  KITS = 3,
  TAG_STRINGS = 4,
  BELT_RATING_TS = 5,
  COVER_SIZE_TS = 6,
  KITS_TS = 7,
  MATERIALS_TS = 8,
  USERS = 9,
  IND_MATERIAL = 10,
  SEARCH = 13
}

function GetImg({arg}){
    switch(arg){
        case DashboardItemType.DECODE_EPC:
            return <Card.Img className="dashboard-item-img" variant="top" src={DecodeEpcIcon} />
        case DashboardItemType.COLDROOM_DEBUG:
            return <Card.Img className="dashboard-item-img" variant="top" src={DebugIcon} />
        case DashboardItemType.COMPANIES:
            return <Card.Img className="dashboard-item-img" variant="top" src={CompanyIcon} />
        case DashboardItemType.KITS:
            return <Card.Img className="dashboard-item-img" variant="top" src={KitIcon} />
        case DashboardItemType.IND_MATERIAL:
            return <Card.Img className="dashboard-item-img" variant="top" src={MaterialIcon} />
        case DashboardItemType.TAG_STRINGS:
            return <Card.Img className="dashboard-item-img" variant="top" src={TagIcon} />
        case DashboardItemType.BELT_RATING_TS:
            return <Card.Img className="dashboard-item-img" variant="top" src={TagIcon} />
        case DashboardItemType.COVER_SIZE_TS:
            return <Card.Img className="dashboard-item-img" variant="top" src={TagIcon} />
        case DashboardItemType.KITS_TS:
            return <Card.Img className="dashboard-item-img" variant="top" src={TagIcon} />
        case DashboardItemType.MATERIALS_TS:
            return <Card.Img className="dashboard-item-img" variant="top" src={TagIcon} />
        case DashboardItemType.USERS:
            return <Card.Img className="dashboard-item-img" variant="top" src={UsersIcon} />
        case DashboardItemType.SEARCH:
            return <Card.Img className="dashboard-item-img" variant="top" src={SearchIcon} />
    }
}

function GetTitle({arg}){
    switch(arg){
        case DashboardItemType.DECODE_EPC:
            return 'Decode EPC';
        case DashboardItemType.COLDROOM_DEBUG:
            return 'Coldroom Debug';
        case DashboardItemType.COMPANIES:
            return 'Companies'; 
        case DashboardItemType.KITS:
            return 'Kits';
        case DashboardItemType.IND_MATERIAL:
            return 'Independent Material';
        case DashboardItemType.TAG_STRINGS:
            return 'Tag Strings'
        case DashboardItemType.BELT_RATING_TS:
            return 'Belt Rating tag strings';
        case DashboardItemType.COVER_SIZE_TS:
            return 'Cover Size tag strings'; 
        case DashboardItemType.KITS_TS:
            return 'Kit tag strings';
        case DashboardItemType.MATERIALS_TS:
            return 'Material tag strings'
        case DashboardItemType.USERS:
            return 'Users'
        case DashboardItemType.SEARCH:
            return 'Search'
    }
}

function GetText({arg}){
    switch(arg){
        case DashboardItemType.DECODE_EPC:
            return 'Decode an existing EPC';
        case DashboardItemType.COLDROOM_DEBUG:
            return 'Debug a fake coldroom';
        case DashboardItemType.COMPANIES:
            return 'Manage coldroom companies'; 
        case DashboardItemType.KITS:
            return 'Create and modify kit tags';
        case DashboardItemType.IND_MATERIAL:
            return 'Create and modify independent material tags';
        case DashboardItemType.TAG_STRINGS:
            return 'Modify Tag Strings'
        case DashboardItemType.USERS:
            return 'Modify users'
        case DashboardItemType.SEARCH:
            return 'Search for tags';
    }
}

function NavButton({arg}){
    var navLink = "/"

    switch(arg){
        case DashboardItemType.DECODE_EPC:
            navLink = '/decode';
            break;
        case DashboardItemType.COLDROOM_DEBUG:
            navLink = '/coldroomDebug';
            break;
        case DashboardItemType.COMPANIES:
            navLink = '/companies';
            break;
        case DashboardItemType.KITS:
            navLink = '/kits';
            break;
        case DashboardItemType.IND_MATERIAL:
            navLink = '/IndependentMaterial';
            break;
        case DashboardItemType.TAG_STRINGS:
            navLink = '/tagStringDash';
            break;
        case DashboardItemType.BELT_RATING_TS:
            navLink = '/editTagStrings?ts=1';
            break;
        case DashboardItemType.COVER_SIZE_TS:
            navLink = '/editTagStrings?ts=2';
            break;
        case DashboardItemType.KITS_TS:
            navLink = '/editTagStrings?ts=3';
            break;
        case DashboardItemType.MATERIALS_TS:
            navLink = '/editTagStrings?ts=4';
            break;
        case DashboardItemType.USERS:
            navLink = '/users';
            break;
        case DashboardItemType.SEARCH:
            navLink = '/search';
            break;
    }

    return <Link to={navLink}>
            <Button className="dashboard-item-button" variant="primary"><b>Select</b></Button>
           </Link>
}

export default class DashboardItem extends React.Component {
    
    type

    constructor(props) {
        super(props);
        this.type = props.type
    }

    render() {
        return (
        <Card className="dashboard-item-card">
            <center><GetImg arg={this.type} /></center>
            <Card.Body>
            <Card.Title><GetTitle arg={this.type} /></Card.Title>
            <Card.Text>
                <GetText arg={this.type} />
            </Card.Text>

            <NavButton arg={this.type} />

            </Card.Body>
        </Card>
        )
    }
}
