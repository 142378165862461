import React from "react"
import TagIcon from '../Assets/ic_tag.png'
import { useSearchParams, Link, Navigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Header from '../Elements/Header.tsx';
import Stack from 'react-bootstrap/Stack';
import {request} from '../Helpers/axios_helper';
import '../Styles/DeleteKit.css'

export class DeleteKit extends React.Component {
    kit
    backLink

    constructor(props) {
        super(props);
        this.state = {loaded: false, kitDeleted: false,  error: null, errorMsg: null}
    }

    onDeleteKit = (e) => {
        e.preventDefault();

        if(this.kit == null){
            return
        }

        request(
            "POST",
            "/deleteKit",
            {
                kitID: this.kit
            }).then(
            (response) => {
                this.setState({
                    kitDeleted:true});
            }).catch(
            (error) => {
                console.log(error)
                this.setState({
                    error:true,
                    errorMsg:error.response.data["message"]});
            }
        );
    }

    render() {
        let {loaded, kitDeleted, error, errorMsg } = this.state
        let kit = 0
        let actuallyThis = this

        function GetKitID(){
            const [searchParams, setSearchParams] = useSearchParams();
            actuallyThis.kit = searchParams.get("kit")
            actuallyThis.backLink = searchParams.get("back")
            actuallyThis.setState({loaded:true})
        }


        return(
            <div>
                {loaded && (
                    <Header backLink={actuallyThis.backLink} />
                )}

                {!loaded && (
                    <GetKitID />
                )}

                {kitDeleted && (
                    <Navigate to={"/"+actuallyThis.backLink} replace={true} />
                )}

                {!kitDeleted && (
                    <center>
                        <Form id="delete-kit-form" onSubmit={this.onDeleteKit}>

                            <center>
                                <img id="delete-kit-logo" src={TagIcon} alt="logo"/>
                            </center>

                            <hr className="form-hr"></hr>

                            {error && 
                                <div id="error-alert" className="alert alert-danger mx-auto" role="alert">
                                <center><b>{errorMsg}</b></center>
                                </div>}

                            <div id="delete-kit-text">
                                This is permanent and cannot be undone.
                                <br/><br/>
                                Are you sure you want to delete this tag?<br/>
                            </div>

                            <center>
                                <Stack id="delete-kit-btn-stack" direction="horizontal" gap={3}>
                                    <Link style={{width:'50%'}} to={'/'+actuallyThis.backLink}>
                                        <Button id="delete-kit-button" variant="primary" type="submit">
                                        NO
                                        </Button>
                                    </Link>
                                    <Button style={{width:'50%'}} id="delete-kit-button" variant="danger" type="submit">
                                    Yes
                                    </Button>
                                </Stack>
                            </center>

                        </Form>
                    </center>
                )}

            </div>
        )
    }
}