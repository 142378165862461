import React, { useEffect, useState } from "react";
import MaterialIcon from '../Assets/ic_material.png'
import Select from 'react-select'
import Form from 'react-bootstrap/Form';
import Header from '../Elements/Header.tsx';
import DatePicker from "react-datepicker";
import Button from 'react-bootstrap/Button';
import {Navigate} from "react-router-dom";
import {request} from '../Helpers/axios_helper.js';
import {TagStrings} from "../Elements/TagStrings.tsx";
import "../Styles/AddKit.css"
import "react-datepicker/dist/react-datepicker.css";

export class AddIndependentMaterial extends React.Component {
    alreadySubmitted = false
    companies
    manufacturedDate = null

    //Keep default values in sync with render()
    kitID = ""
    companyID = ""
    epc = ""
    parsedDate = ""
    expiry = "26"
    manufacturer = ""
    name = ""
    location = ""
    data = ""
    
    constructor(props) {
        super(props);
        this.state = {loading: true, kitAdded: false, error: null, errorMsg: null};
        request(
            "GET",
            "/getAllCompanies",
            {
                
            }).then(
            (response) => {
                this.companies = response.data.companies
                this.setState({loading: false});
            }).catch(
            (error) => {
                console.log(error)
            });
    }

    onSetError = (errorMessage) => {
        this.setState({
            error:true,
            errorMsg:errorMessage});
        window.scrollTo(0, 0)
    }

    onChangeHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value.trim();
        console.log(name+": "+value)

        switch(name){
            case "company":
                this.companyID = value
                break;
            case "expiry":
                this.expiry = value
                break;
            case "manufacturer":
                event.target.value = this.sanitizeText(event.target.value)
                this.manufacturer = event.target.value.trim()
                break;
            case "name":
                event.target.value = this.sanitizeText(event.target.value)
                this.name = event.target.value.trim()
                break;
            case "location":
                event.target.value = this.sanitizeText(event.target.value)
                this.location = event.target.value.trim()
                break;
        }

        this.encodeThirdPartyData()
    }

    encodeThirdPartyData = () => {
        var companyName = ""
        for (let i = 0; i < this.companies.length; i++) {
            const company = this.companies[i];
            if(company.id == this.companyID){
                companyName = company.name
            }
        }

        var json = {
            "name":this.name,
            "company":companyName,
            "manufacturer":this.manufacturer,
            "location":this.location
        };

        this.data = btoa(JSON.stringify(json))
        //JSON.parse(atob(encoded))
    }

    sanitizeText = (text) => {
        return text.replace(/[^A-Za-z0-9 #]/gi, "").substring(0,120);
    }

    onSetKitEPC = () =>{
        request(
            "POST",
            "/setKitEPC",
            {
                kitID: parseInt(this.kitID),
                epc: this.epc
            }).then(
            (response) => {
                this.setState({kitAdded: true});
            }).catch(
            (error) => {
                this.alreadySubmitted = false;
                console.log(error)
                this.setState({
                    error:true,
                    errorMsg:error.response.data["message"]});
                window.scrollTo(0, 0)
            }
        );
    }

    onCreateEPC = () => {
        request(
            "POST",
            "/createTag2",
            {
                universal: {
                    kitID: this.kitID,
                    tagType: 0,
                    date: parseInt(this.parsedDate),
                    expiry: parseInt(this.expiry)
                }
            }).then(
            (response) => {
                this.epc = response.data.EPC
                this.onSetKitEPC()
            }).catch(
            (error) => {
                this.alreadySubmitted = false;
                console.log(error)
                this.setState({
                    error:true,
                    errorMsg:error.response.data["message"]});
                window.scrollTo(0, 0)
            }
        );
    }

    onCreateKit = () => {
        request(
            "POST",
            "/createThirdPartyKit",
            {
                companyID: parseInt(this.companyID),
                data: this.data
            }).then(
            (response) => {
                this.kitID = response.data.id
                this.onCreateEPC()
            }).catch(
            (error) => {
                this.alreadySubmitted = false;
                console.log(error)
                this.setState({
                    error:true,
                    errorMsg:error.response.data["message"]});
                window.scrollTo(0, 0)
            }
        );
    }

    onVerifyEPCData = () => {
        request(
            "POST",
            "/createTag2",
            {
                universal: {
                    kitID: 1,
                    tagType: 2,
                    date: parseInt(this.parsedDate),
                    expiry: parseInt(this.expiry)
                }
            }).then(
            (response) => {
                //EPC Data verified
                this.onCreateKit()
            }).catch(
            (error) => {
                this.alreadySubmitted = false;
                console.log(error)
                this.setState({
                    error:true,
                    errorMsg:error.response.data["message"]});
                window.scrollTo(0, 0)
            }
        );
    }

    onAddKit = (e) => {
        e.preventDefault();
        if(this.alreadySubmitted){
            return;
        }

        this.alreadySubmitted = true
        this.onVerifyEPCData()
    }
    
    render(){
        let {loading, kitAdded, error, errorMsg} = this.state
        var tagStrings = TagStrings.getTagStrings()

        const ManufacturedDate = () => {
            const [startDate, setStartDate] = useState(new Date());

            this.manufacturedDate = startDate
            var start = new Date(startDate.getFullYear(), 0, 0);
            var diff = (startDate - start) + ((start.getTimezoneOffset() - startDate.getTimezoneOffset()) * 60 * 1000);
            var oneDay = 1000 * 60 * 60 * 24;
            var day = String(Math.floor(diff / oneDay));
            while(day.length < 3){
                day = "0"+day
            }

            this.parsedDate = String(startDate.getFullYear())+String(day)
            return (
              <DatePicker
                inline
                selected={startDate}
                onChange={(date) => setStartDate(date)} />
            );
        };

        const Companies = (props) => {
            var options = []

            for (let i = 0; i < this.companies.length; i++) {
                const company = this.companies[i];
                if(i == 0){
                    this.companyID = company.id
                }

                options.push({ value: company.id, label: company.name });   
            }

            return <Select 
                        name="company"
                        defaultValue={options[0]}
                        placeholder={<div className="add-kit-select-placeholder">{props.placeholderText}</div>} 
                        className="add-kit-select-option" 
                        classNamePrefix="add-kit-selected-option"
                        onChange={(choice) => this.onChangeHandler({target:{name:"company",value:String(choice.value)}})}
                        options={options} />
        }

        return(
            <div>
                <Header backLink="independentMaterial" />

                {kitAdded && (
                    <Navigate to="/independentMaterial" replace={true} />
                )}

                {loading && (
                    <div id="loading-div">Loading...</div>
                )}

                {!loading && (
                    <center>
                    <Form id="add-kit-form" onSubmit={this.onAddKit}>
                        <center>
                            <img id="add-kit-logo" src={MaterialIcon} alt="logo"/>
                        </center>

                        <hr className="form-hr"></hr>

                        {error && 
                        <div id="error-alert" class="alert alert-danger mx-auto" role="alert">
                        <center><b>{errorMsg}</b></center>
                        </div>}

                        <div className="add-kit-form-label">Manufactured Date</div>
                        <ManufacturedDate />
                        <br />
                        <br />
                        <Form.Group className="mb-3">
                            <Form.Label className="add-kit-form-label">Expiry Weeks</Form.Label>
                            <Form.Control 
                                className="add-kit-form-input" 
                                onInput = {(e) =>{
                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,3)
                                }}
                                type="number" 
                                name="expiry" 
                                defaultValue={this.expiry} onChange={this.onChangeHandler}/>
                            <Form.Text className="text-muted">
                            The number of weeks after the manufactured date this material expires.
                            </Form.Text>
                        </Form.Group>
                        <div className="add-kit-form-label">Company</div>
                        <Companies />
                        <br />
                        <Form.Group className="mb-3">
                            <Form.Label className="add-kit-form-label">Manufacturer</Form.Label>
                            <Form.Control 
                                className="add-kit-form-input-large" 
                                type="text" 
                                name="manufacturer" 
                                onChange={this.onChangeHandler}/>
                            <Form.Text className="text-muted">
                            What company made this material?
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="add-kit-form-label">Name</Form.Label>
                            <Form.Control 
                                className="add-kit-form-input-large" 
                                type="text" 
                                name="name" 
                                onChange={this.onChangeHandler}/>
                            <Form.Text className="text-muted">
                            Name this material something unique.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="add-kit-form-label">Location</Form.Label>
                            <Form.Control 
                                className="add-kit-form-input-large" 
                                type="text" 
                                name="location" 
                                onChange={this.onChangeHandler}/>
                            <Form.Text className="text-muted">
                            The location this material is being shipped to.
                            </Form.Text>
                        </Form.Group>
                        

                        <Button id="add-kit-btn" variant="primary" type="submit">
                            ADD
                        </Button>
                    </Form>
                    <br />
                    </center>
                )}
            </div>
        )
    }
}