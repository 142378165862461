import React from 'react'
import {getAuthToken, getLoginTime} from '../Helpers/axios_helper';
import {Navigate} from "react-router-dom";

export default class LoginCheck extends React.Component {

    constructor(props) {
        super(props);
    }

    render(){
        function LoginCheck(){
            if(getAuthToken() == null){
                return <Navigate to={"/"} replace={true} />
            }

            if(Date.now()-getLoginTime() > 14400000){
                return <Navigate to={"/"} replace={true} /> 
            }
        }

        return(
            <LoginCheck />
        )
    }
}