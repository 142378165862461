import {request} from '../Helpers/axios_helper.js';

const localStorageKey = "tagStrings"
export class TagStrings {
    static alreadySet = false

    static checkForUpdate(){
        request(
            "POST",
            "/tagStringsVersion",
            {
                lang: "en",
                companyKey: "VU8UC6" //Almex stoney creek
            }).then(
            (response) => {
                var tagStrings = window.localStorage.getItem(localStorageKey);

                if(tagStrings == null){
                    TagStrings.forceUpdateTagStrings();
                    return;
                }

                if(JSON.parse(tagStrings).version < response.data){
                    TagStrings.forceUpdateTagStrings();
                }
            }).catch(
            (error) => {
                console.log(error)
            }
        ); 
    }

    static forceUpdateTagStrings(){
        console.log("Updating tag strings");
        request(
            "POST",
            "/tagStrings",
            {
                lang: "en",
                companyKey: "VU8UC6" //Almex stoney creek
            }).then(
            (response) => {
                window.localStorage.setItem(
                    localStorageKey,
                    JSON.stringify(response.data))
                this.alreadySet = true
            }).catch(
            (error) => {
                console.log(error)
            }
        );
    }

    static reloadTagStrings(){
        window.localStorage.removeItem(localStorageKey)
        this.forceUpdateTagStrings()
    }

    static getTagStrings(){
        var tagStrings = window.localStorage.getItem(localStorageKey);
        if(tagStrings == null){
            console.log("getTagStrings called when not set!")
            return null;
        } else {
            return JSON.parse(tagStrings)
        }
    }
}