import React from "react"
import "../Styles/Login.css"
import {request, setAuthHeader, setLoginTime} from '../Helpers/axios_helper';
import Logo from '../Assets/ColdRoomLogo.png'
import Logo2 from '../Assets/ColdRoomLogo_REV.png'
import Logo3 from '../Assets/AlmexFusion.png'
import {Navigate} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import { UserInfo } from "../Elements/UserInfo.tsx";

export default class Login extends React.Component {
    state = {user: null, error: null, errorMsg: null, register: null};

    username = ""
    password = ""

    constructor(props) {
        super(props);
        setAuthHeader(null);
    }

    onChangeHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        switch(name){
            case 'username':
                this.username = value;
            case 'password':
                this.password = value;
        }
    };

    onLogin = (e) => {
        e.preventDefault();
        setAuthHeader(null);
        request(
            "POST",
            "/login",
            {
                login: this.username,
                password: this.password
            }).then(
            (response) => {
                if(response.data.disabled){
                    this.setState({
                        error:true,
                        errorMsg:"Your account is disabled"});
                    setAuthHeader(null);
                    return;
                }

                UserInfo.setUserInfo(response.data);
                setAuthHeader(response.data.token);
                setLoginTime();
                let user = true;
                this.setState({user:true});
            }).catch(
            (error) => {
                console.log(error)
                setAuthHeader(null);
                this.setState({
                    error:true,
                    errorMsg:"Invalid username or password"});
            }
        );
    };

    onSubmitLogin = (e) => {
        this.onLogin(e);
    };

    onRegister = () => {
        this.setState({register:true});
    }

    render() {
        let { user, error, errorMsg, register } = this.state;
        return(
            <div id="login-root-div" style={{display:"flex", justifyContent:"space-between"}}>

                {user && (
                    <Navigate to="/dashboard" replace={true} />
                )}

                {register && (
                    <Navigate to="/register" replace={true} />
                )}

                <Container id="login-container" fluid>
                    <Row>

                        <Col id="login-form-col" xs={12} sm={12} md={12} lg={6}>

                            <Form id="login-form" onSubmit={this.onSubmitLogin}>

                                <center>
                                    <img id="login-logo" src={Logo} alt="logo"/>
                                    <p id="login-subtitle"><b>PRODUCTION</b></p>
                                </center>

                                <hr className="form-hr"></hr>

                                {error && 
                                    <div id="error-alert" class="alert alert-danger mx-auto" role="alert">
                                    <center><b>{errorMsg}</b></center>
                                    </div>}
                                            
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="form-label">Username</Form.Label>
                                    <Form.Control name= "username" className="form-text-input" type="text" autoCapitalize="none" onChange={this.onChangeHandler} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label className="form-label">Password</Form.Label>
                                    <Form.Control name= "password" className="form-text-input" type="password" autoCapitalize="none" onChange={this.onChangeHandler} />
                                </Form.Group>

                                <Stack direction="horizontal">
                                    <a id="login-forgot" className="p-2 ms-auto" onClick={this.onRegister}>Register</a>
                                </Stack>

                                <center>
                                    <Button id="login-button" variant="primary" type="submit">
                                    LOG IN
                                    </Button>
                                </center>

                            </Form>
                        </Col>

                        <Col className="d-none d-lg-block">
                            <center>
                                <img id="login-side-logo" src={Logo2} width={750} alt="logo2" />
                            </center>
                        </Col>

                    </Row>
                    
                    <Row>

                        <Col>
                            <img id="fusion_logo" src={Logo3} alt="logo3"/>
                        </Col>

                    </Row>
                </Container>
            
            </div>
        )
    }
}
