import React from 'react'
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import EPCTable from "../Elements/EPCTable.tsx";
import '../Styles/ArrayTable.css'

export enum ArrayTableMode{
    COMPANIES = 0,
    KITS = 1,
    COLDROOM_DEBUG = 2,
    USERS = 3,
    IND_MATERIAL = 4,
    SEARCH = 5,
    KIT_MATERIAL = 6
  }

export default class ArrayTable extends React.Component {
    state = {validArray: false, lastUpdate: 0, array: []};
    mode
    showModalFunc
    getCompanyNameFunc
    setUserDisabledFunc
    backLink
    errorMsg = "No Data"
    keyCount = 0
    propArray = []

    constructor(props) {
        super(props);
        this.mode = props.mode
        this.showModalFunc = props.showModalFunc
        this.getCompanyNameFunc = props.getCompanyNameFunc
        this.setUserDisabledFunc = props.setUserDisabledFunc
        this.propArray = props.array
        this.backLink = props.backLink
        if(props.errorMsg != null){
            this.errorMsg = props.errorMsg
        }
    }

    componentDidMount() {
        if(this.propArray.length > 0){
            this.setState({array:this.propArray,validArray:true});
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.array.length > 0){
            this.setState({array:nextProps.array,validArray:true});
        }
    }

    render() {
        let {validArray, array} = this.state;
        let sortedkeys = Array();
        let actuallyThis = this // :D

        function UniqueKey(){
            actuallyThis.keyCount += 1
            return actuallyThis.keyCount
        }

        function SortKeys(){
            let keys = Object.keys(array[0]);
            sortedkeys = Array();

            if(actuallyThis.mode == ArrayTableMode.USERS){
                sortedkeys.push("id");
                sortedkeys.push("login");
                sortedkeys.push("first_name");
                sortedkeys.push("last_name");
                sortedkeys.push("admin");
                sortedkeys.push("disabled");
                return;
            }

 
            for (let index = 0; index < keys.length; index++) {
                if(keys[index].toLowerCase() == "id"){
                    sortedkeys.push("id");
                }
            }
            
            for (let index = 0; index < keys.length; index++) {
                if(keys[index].toLowerCase() == "epc"){
                    sortedkeys.push("epc");
                }
            }

            for (let index = 0; index < keys.length; index++) {
                if(keys[index].toLowerCase() == "name"){
                    sortedkeys.push("name");
                }
            }

            for (let index = 0; index < keys.length; index++) {
                if(keys[index].toLowerCase() == "id"){
                    continue;
                }

                if(keys[index].toLowerCase() == "epc"){
                    continue;
                }

                if(keys[index].toLowerCase() == "name"){
                    continue;
                }

                if((actuallyThis.mode == ArrayTableMode.IND_MATERIAL || actuallyThis.mode == ArrayTableMode.SEARCH) &&
                   keys[index].toLowerCase() == "data"){
                    continue;
                }

                sortedkeys.push(keys[index]);
            }
        }

        function RenderHeader() {
            const head : React.JSX.Element[] = []
            SortKeys();

            sortedkeys.map((key) => {
                head.push(<th 
                            key={"key-"+UniqueKey()}>
                            {key.replace("_"," ")}
                          </th>)
            });

            if(actuallyThis.mode == ArrayTableMode.KITS || actuallyThis.mode == ArrayTableMode.SEARCH ){
                head.push(<th key={"key-"+UniqueKey()}>Materials</th>);
                head.push(<th key={"key-"+UniqueKey()}>Delete</th>);
            }

            if(actuallyThis.mode == ArrayTableMode.IND_MATERIAL){
                head.push(<th key={"key-"+UniqueKey()}>Delete</th>);
            }
            
            return head;
        }

        function EPCRow(key,value){
            return <td 
                    onClick={() => actuallyThis.showModalFunc(value[key])} 
                    key={"key-"+UniqueKey()}
                    className='array-table-body-td array-table-epc'>
                        {value[key]}
                    </td>
        }

        function EPCRow2(key,value){
            return <td 
                    key={"key-"+UniqueKey()}
                    className='array-table-body-td'>
                        <EPCTable epc={value[key]} icon={false} />
                    </td>
        }

        function TimeRow(key,value){
            var dateTime = new Date(value[key]);
            return <td
                    className="array-table-body-td" 
                    key={"key-"+UniqueKey()}>
                        {dateTime.toLocaleString('default', { month: 'long' })+" "+
                         dateTime.getDate()+", "+
                         dateTime.getHours()+":"+
                         dateTime.getMinutes()+" "+
                         dateTime.getFullYear()}
                    </td>
        }

        function StatusRow(key,value){
            var status;
            if(value[key] == 0){
                status = "OUT";
            } else {
                status = "IN";
            }

            return <td
                    className="array-table-body-td" 
                    key={"key-"+UniqueKey()}>
                        <b>{status}</b>
                    </td>
        }

        function KitMaterialTD(kitID){            
            return <td 
                    key={"key-"+UniqueKey()}
                    className="array-table-body-td">
                        <Link to={"/kitMaterial?kit="+kitID+"&back="+actuallyThis.backLink}>
                            <Button 
                                className="array-table-kit-btn" 
                                variant="secondary">
                                    <b>Edit</b>
                            </Button>
                        </Link>
                   </td>
        }

        function kitDeleteTD(kitID,shipped){
            if(shipped == "true"){
                return <td 
                        className="array-table-body-td" 
                        key={"key-"+UniqueKey()}>
                            &nbsp;
                        </td>
            } else if(actuallyThis.mode == ArrayTableMode.KITS){
                return <td 
                        className="array-table-body-td" 
                        key={"key-"+UniqueKey()}>
                            
                            <Link to={"/deleteKit?kit="+kitID+"&back="+actuallyThis.backLink}>
                            <Button 
                                className="array-table-kit-btn" 
                                variant="danger">
                                    <b>Delete</b>
                            </Button>
                            </Link>
                        </td>
            } else if(actuallyThis.mode == ArrayTableMode.SEARCH){
                return <td 
                        className="array-table-body-td" 
                        key={"key-"+UniqueKey()}>
                            
                            <Link to={"/deleteKit?kit="+kitID+"&back="+actuallyThis.backLink}>
                            <Button 
                                className="array-table-kit-btn" 
                                variant="danger">
                                    <b>Delete</b>
                            </Button>
                            </Link>
                        </td>
            } else if(actuallyThis.mode == ArrayTableMode.IND_MATERIAL){
                return <td 
                        className="array-table-body-td" 
                        key={"key-"+UniqueKey()}>
                            
                            <Link to={"/deleteKit?kit="+kitID+"&back="+actuallyThis.backLink}>
                            <Button 
                                className="array-table-kit-btn" 
                                variant="danger">
                                    <b>Delete</b>
                            </Button>
                            </Link>
                        </td>
            }
        }

        function userDisabledTD(userID,disabled){
            if(disabled == true){
                return <td 
                    className="array-table-body-td" 
                    key={"key-"+UniqueKey()}>
                            
                        <Button
                            onClick={() => actuallyThis.setUserDisabledFunc(userID,false)} 
                            className="array-table-kit-btn" 
                            variant="danger">
                                <b>Yes</b>
                        </Button>
                            
                    </td>
            } else {
                return <td 
                    className="array-table-body-td" 
                    key={"key-"+UniqueKey()}>
                            
                        <Button
                            onClick={() => actuallyThis.setUserDisabledFunc(userID,true)}  
                            className="array-table-kit-btn" 
                            variant="secondary">
                                <b>No</b>
                        </Button>
                            
                    </td>
            }
        }

        function CompanyName(companyID){
            return <td 
                    className="array-table-body-td" 
                    key={"key-"+UniqueKey()}>
                        {actuallyThis.getCompanyNameFunc(companyID)}
                    </td>
        }

        function ShippedStatus(kitID,shipped){
            if(shipped == "true"){
                return <td 
                        className="array-table-body-td" 
                        key={"key-"+UniqueKey()}>
                            {"Yes"}
                        </td>
            } else if(actuallyThis.mode == ArrayTableMode.KITS){
                return <td 
                        className="array-table-body-td" 
                        key={"key-"+UniqueKey()}>
                            <Link to={"/shipKit?kit="+kitID+"&back="+actuallyThis.backLink}>
                            <Button 
                                className="array-table-kit-btn" 
                                variant="secondary">
                                    <b>Ship</b>
                            </Button>
                            </Link>
                        </td>
            } else if(actuallyThis.mode == ArrayTableMode.SEARCH){
                return <td 
                        className="array-table-body-td" 
                        key={"key-"+UniqueKey()}>
                            <Link to={"/shipKit?kit="+kitID+"&back="+actuallyThis.backLink}>
                            <Button 
                                className="array-table-kit-btn" 
                                variant="secondary">
                                    <b>Ship</b>
                            </Button>
                            </Link>
                        </td>
            } else if(actuallyThis.mode == ArrayTableMode.IND_MATERIAL){
                return <td 
                        className="array-table-body-td" 
                        key={"key-"+UniqueKey()}>
                            
                            <Link to={"/shipKit?kit="+kitID+"&back="+actuallyThis.backLink}>
                            <Button 
                                className="array-table-kit-btn" 
                                variant="secondary">
                                    <b>Ship</b>
                            </Button>
                            </Link>
                        </td>
            }
        }

        function RenderBody(){
            const body : React.JSX.Element[] = []
            array.map((value, index) => {
                var row : React.JSX.Element[] = []
                var id = 0
                var shipped = "true"

                sortedkeys.map(key => {
                    switch(key){
                        case "id":
                            id = value[key];
                            row.push(<td 
                                className="array-table-body-td" 
                                key={"key-"+UniqueKey()}>
                                    {value[key]}
                                </td>)
                            break;
                        case "kit_id":
                            if(actuallyThis.mode == ArrayTableMode.KIT_MATERIAL){
                                row.push(KitMaterialTD(value[key]));
                            }
                            break;
                        case "epc":
                            row.push(EPCRow2(key,value))
                            break;
                        case "time":
                            row.push(TimeRow(key,value))
                            break;
                        case "company_id":
                            row.push(CompanyName(value[key]))
                            break
                        case "shipped":
                            shipped = value[key]
                            row.push(ShippedStatus(id,value[key]))
                            break;
                        case "status":
                            row.push(StatusRow(key,value))
                            break;
                        case "disabled":
                            if(actuallyThis.mode == ArrayTableMode.USERS){
                                row.push(userDisabledTD(id,value[key]))
                                break;
                            }
                        default:
                            if (typeof value[key] == "boolean") {
                                value[key] = value[key].toString();
                            }

                            row.push(<td 
                                    className="array-table-body-td" 
                                    key={"key-"+UniqueKey()}>
                                        {value[key]}
                                    </td>)
                    }
                })

                if(actuallyThis.mode == ArrayTableMode.KITS || actuallyThis.mode == ArrayTableMode.SEARCH){
                    row.push(KitMaterialTD(id));
                    row.push(kitDeleteTD(id,shipped))
                }

                if(actuallyThis.mode == ArrayTableMode.IND_MATERIAL){
                    row.push(kitDeleteTD(id,shipped))
                }
                
                body.push(<tr key={"key-"+UniqueKey()}>{row}</tr>);
                
            });
            
            return body
        }

        return (
            <div>
                {!validArray &&
                    <div id="error-alert" className="alert alert-danger mx-auto" role="alert">
                    <center><b>{actuallyThis.errorMsg}</b></center>
                    </div>
                }

                {validArray &&
                    <Table id="array-table" striped bordered hover responsive size="md">
                        <thead>
                            <tr>
                                <RenderHeader />
                            </tr>
                        </thead>
                        <tbody>
                                <RenderBody />
                        </tbody>
                    </Table>
                }
            </div>
        )
    }
}