import React from "react"
import {request} from '../Helpers/axios_helper';
import ArrayTable from "../Elements/ArrayTable.tsx";
import {ArrayTableMode} from "../Elements/ArrayTable.tsx";
import Header from '../Elements/Header.tsx';
import UserIcon from '../Assets/ic_users.png'
import {
    BrowserRouter as Router,
    Route,
    Link,
    useParams
  } from "react-router-dom";
import "../Styles/Users.css"

export class Users extends React.Component {
    users

    constructor(props) {
        super(props);
        this.onReloadUsers();
    }

    onReloadUsers = () => {
        this.state = {loading: true, error: null, errorMsg: null};
        request(
            "GET",
            "/getAllUsers",
            {
                
            }).then(
            (response) => {
                this.users = response.data.users
                this.setState({loading: false});
            }).catch(
            (error) => {
                console.log(error)
                this.setState({
                    error:true,
                    errorMsg:error.response.data["message"]});
            });
    }

    onSetUserDisabled = (userID,disabled) => {
        this.setState({loading: true});
        request(
            "POST",
            "/setUserDisabled",
            {
                userID: userID,
                disabled: disabled
            }).then(
            (response) => {
                console.log(response);
                this.onReloadUsers();
            }).catch(
            (error) => {
                console.log(error)
                this.setState({
                    error:true,
                    errorMsg:error.response.data["message"]});
                this.onReloadUsers();
            }
        );
    }

    render() {
        let { loading, error, errorMsg } = this.state
        
        return(
            <div>
                <Header backLink="dashboard" />

                {loading && (
                    <div id="loading-div">Loading...</div>
                )}

                {error && 
                    <div id="error-alert" className="alert alert-danger mx-auto" role="alert">
                    <center><b>{errorMsg}</b></center>
                    </div>}

                {!loading && (
                    <div id="content">
                        <center>
                            <img id="users-icon" src={UserIcon} />
                            <br />
                            <span id="users-title" >Users</span>
                        </center>
                        <ArrayTable 
                            array={this.users}
                            mode={ArrayTableMode.USERS}
                            setUserDisabledFunc={this.onSetUserDisabled}  />
                    </div>
                )}

            </div>
        )
    }
}