const localStorageKey = "userInfo"

export class UserInfo {

    static setUserInfo(userInfo){
        window.localStorage.setItem(
            localStorageKey,
            JSON.stringify(userInfo))
    }

    static getUserInfo(){
        var userInfo = window.localStorage.getItem(localStorageKey);
        if(userInfo == null){
            console.log("getUserInfo called when not set!")
            return null;
        } else {
            return JSON.parse(userInfo)
        }
    }
}