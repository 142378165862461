import React from "react"
import {request, setAuthHeader} from '../Helpers/axios_helper.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DashboardItem from "../Elements/DashboardItem.tsx";
import {DashboardItemType} from "../Elements/DashboardItem.tsx";
import Header from '../Elements/Header.tsx';
import {TagStrings} from "../Elements/TagStrings.tsx";
import "../Styles/TagStringsDash.css"

export class TagStringDash extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div>
                <Header backLink="dashboard" />

                <div id="error-alert" className="alert alert-info mx-auto" role="alert">
                    <center><b>The tags exclusively hold numerical data<br/><br/>To turn those numbers into text/strings we use the below tag strings</b></center>
                </div>
                <br/>
                <Container id="location-button-container" fluid>
                    <Row className="justify-content-center">
                        <DashboardItem type={DashboardItemType.BELT_RATING_TS}/>
                        <DashboardItem type={DashboardItemType.COVER_SIZE_TS}/>
                        <DashboardItem type={DashboardItemType.KITS_TS}/>
                        <DashboardItem type={DashboardItemType.MATERIALS_TS}/>
                    </Row>
                </Container>
            </div>

        )
    }
}