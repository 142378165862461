import React from "react"
import {request} from '../Helpers/axios_helper';
import ArrayTable from "../Elements/ArrayTable.tsx";
import Header from '../Elements/Header.tsx';
import CompanyIcon from '../Assets/ic_company.png'
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import {
    BrowserRouter as Router,
    Route,
    Link,
    useParams
  } from "react-router-dom";
import "../Styles/Companies.css"

export class Companies extends React.Component {
    companies;

    constructor(props) {
        super(props);
        this.state = {loading: true};
        request(
            "GET",
            "/getAllCompanies",
            {
                
            }).then(
            (response) => {
                this.companies = response.data.companies
                this.setState({loading: false});
            }).catch(
            (error) => {
                console.log(error)
            });
    }

    render() {
        let { loading } = this.state
        return(
            <div>
                <Header backLink="dashboard" />

                {loading && (
                    <div id="loading-div">Loading...</div>
                )}

                {!loading && (
                    <div id="content">
                        <center>
                            <img id="companies-icon" src={CompanyIcon} />
                            <br />
                            <span id="companies-title" >Companies</span>
                        </center>
                        <ArrayTable array={this.companies} />
                        <Stack>
                            <Link to="/addCompany" className="p-2 ms-auto">
                                <Button id="add-new-company-btn" className="ms-auto" variant="primary"><b>Add New</b></Button>
                            </Link>
                        </Stack>
                    </div>
                )}
            </div>
        )
    }
}