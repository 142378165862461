import React, { useEffect, useState } from "react";
import { useSearchParams, Link, Navigate } from 'react-router-dom';
import {request} from '../Helpers/axios_helper';
import TagIcon from '../Assets/ic_tag.png'
import Form from 'react-bootstrap/Form';
import Header from '../Elements/Header.tsx';
import Button from 'react-bootstrap/Button';
import "../Styles/AddTagString.css"

export class AddTagString extends React.Component {
    alreadySubmitted = false
    tagStringID
    beltRating
    coverSize
    title
    subtitle
    partNum
    partDesc

    constructor(props) {
        super(props);
        this.state = {loading: true, tagStringAdded: false, error: null, errorMsg: null};
    }

    onSetError = (errorMessage) => {
        this.setState({
            error:true,
            errorMsg:errorMessage});
        window.scrollTo(0, 0)
        this.alreadySubmitted = false;
    }

    onAddBeltRating = () => {
        request(
            "POST",
            "/addTagStringBeltRating",
            {
                beltRating: this.beltRating,
                lang: "en"
            }).then(
            (response) => {
                this.setState({tagStringAdded: true});
            }).catch(
            (error) => {
                this.alreadySubmitted = false;
                console.log(error)
                this.onSetError(error.response.data["message"])
            }
        );
    }

    onAddCoverSize = () => {
        request(
            "POST",
            "/addTagStringCoverSize",
            {
                coverSize: this.coverSize,
                lang: "en"
            }).then(
            (response) => {
                this.setState({tagStringAdded: true});
            }).catch(
            (error) => {
                this.alreadySubmitted = false;
                console.log(error)
                this.onSetError(error.response.data["message"])
            }
        );
    }

    onAddKit = () => {
        request(
            "POST",
            "/addTagStringKit",
            {
                title: this.title,
                subtitle: this.subtitle,
                lang: "en"
            }).then(
            (response) => {
                this.setState({tagStringAdded: true});
            }).catch(
            (error) => {
                this.alreadySubmitted = false;
                console.log(error)
                this.onSetError(error.response.data["message"])
            }
        );
    }

    onAddMaterial = () => {
        request(
            "POST",
            "/addTagStringMaterial",
            {
                partNum: this.partNum,
                partDesc: this.partDesc,
                lang: "en"
            }).then(
            (response) => {
                this.setState({tagStringAdded: true});
            }).catch(
            (error) => {
                this.alreadySubmitted = false;
                console.log(error)
                this.onSetError(error.response.data["message"])
            }
        );
    }

    onChangeHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value.trim();

        switch(name){
            case "beltRating":
                this.beltRating = value;
                break;
            case "coverSize":
                this.coverSize = value;
                break;
            case "title":
                this.title = value;
                break;
            case "subtitle":
                this.subtitle = value;
                break;
            case "partNum":
                this.partNum = value;
                break;
            case "partDesc":
                this.partDesc = value;
                break;
        }
    }

    onSubmit = (e) => {
        e.preventDefault();

        if(this.alreadySubmitted){
            return;
        }

        this.setState({error:false});

        switch(this.tagStringID){
            case "1":
                if(this.beltRating == null || this.beltRating.length == 0){
                    this.onSetError("Belt rating is empty");
                    return;
                }
                this.onAddBeltRating();
                break;
            case "2":
                if(this.coverSize == null || this.coverSize.length == 0){
                    this.onSetError("Cover size is empty");
                    return;
                }
                this.onAddCoverSize();
                break;
            case "3":
                if(this.title == null || this.title.length == 0){
                    this.onSetError("Kit grade is empty");
                    return;
                }

                if(this.subtitle == null || this.subtitle.length == 0){
                    this.onSetError("Kit ply is empty");
                    return;
                }
                this.onAddKit();
                break;
            case "4":
                if(this.partNum == null || this.partNum.length == 0){
                    this.onSetError("Part number is empty");
                    return;
                }

                if(this.partDesc == null || this.partDesc.length == 0){
                    this.onSetError("Part description is empty");
                    return;
                }
                this.onAddMaterial();
                break;
        }
    }

    render(){
        let actuallyThis = this
        let {loading, tagStringAdded, error, errorMsg} = this.state

        function GetID(){
            const [searchParams, setSearchParams] = useSearchParams();
            actuallyThis.tagStringID = searchParams.get("ts")
            actuallyThis.setState({loading:false});
        }

        function BeltRatingForm(){
            return <Form.Group className="mb-3">
                    <Form.Label className="add-tag-string-form-label">Belt Rating</Form.Label>
                    <Form.Control 
                        className="add-tag-string-form-input"
                        maxLength={100}
                        type="text" 
                        name="beltRating" 
                        onChange={actuallyThis.onChangeHandler}/>
                </Form.Group>
        }

        function CoverSizeForm(){
            return <Form.Group className="mb-3">
                    <Form.Label className="add-tag-string-form-label">Cover Size</Form.Label>
                    <Form.Control 
                        className="add-tag-string-form-input"
                        maxLength={100}
                        type="text" 
                        name="coverSize" 
                        onChange={actuallyThis.onChangeHandler}/>
                    <Form.Text className="text-muted">
                        The cover size in fractions of an inch
                    </Form.Text>
                </Form.Group>
        }

        function KitForm(){
            return <Form.Group className="mb-3">
                    <Form.Label className="add-tag-string-form-label">Kit Grade</Form.Label>
                    <Form.Control 
                        className="add-tag-string-form-input"
                        maxLength={100}
                        type="text" 
                        name="title" 
                        onChange={actuallyThis.onChangeHandler}/>
                    <Form.Text className="text-muted">
                        The grade of the kit <br/>
                        Ex. Standard Grade Conveyor Belting
                    </Form.Text>
                    <br/>
                    <br/>
                    <Form.Label className="add-tag-string-form-label">Kit Ply</Form.Label>
                    <Form.Control 
                        className="add-tag-string-form-input"
                        maxLength={100}
                        type="text" 
                        name="subtitle" 
                        onChange={actuallyThis.onChangeHandler}/>
                    <Form.Text className="text-muted">
                        The ply of the kit <br/>
                        Ex. 3 Ply Step Splice Kit
                    </Form.Text>
                </Form.Group>
        }

        function MaterialForm(){
            return <Form.Group className="mb-3">
                <Form.Label className="add-tag-string-form-label">Part Number</Form.Label>
                <Form.Control 
                    className="add-tag-string-form-input"
                    maxLength={100}
                    type="text" 
                    name="partNum" 
                    onChange={actuallyThis.onChangeHandler}/>
                <Form.Text className="text-muted">
                    The Epicor part number
                </Form.Text>
                <br/>
                <br/>
                <Form.Label className="add-tag-string-form-label">Part Description</Form.Label>
                <Form.Control 
                    className="add-tag-string-form-input"
                    maxLength={100}
                    type="text" 
                    name="partDesc" 
                    onChange={actuallyThis.onChangeHandler}/>
            </Form.Group>
        }

        function FormContent(){
            switch(actuallyThis.tagStringID){
                case "1":
                    return BeltRatingForm();
                case "2":
                    return CoverSizeForm();
                case "3":
                    return KitForm();
                case "4":
                    return MaterialForm();
            }
        }

        return(
            <div>
                {!loading && (
                    <Header backLink={"editTagStrings?ts="+actuallyThis.tagStringID} />
                )}

                {tagStringAdded && (
                    <Navigate to={"/editTagStrings?ts="+actuallyThis.tagStringID} replace={true} />
                )}

                {loading && (
                    <GetID />
                )}

                {!loading && (
                    <center>
                        <div id="error-alert" className="alert alert-danger mx-auto" role="alert">
                        <center><b>Double check all fields!<br/>This cannot be modified once added</b></center>
                        </div>
                        <br/>

                        <Form id="add-tag-string-form" onSubmit={actuallyThis.onSubmit}>
                            <center>
                                <img id="add-tag-string-logo" src={TagIcon} alt="logo"/>
                            </center>

                            <hr className="form-hr"></hr>

                            {error && 
                            <div id="error-alert" className="alert alert-danger mx-auto" role="alert">
                            <center><b>{errorMsg}</b></center>
                            </div>}

                            <FormContent />

                            <Button id="add-tag-string-btn" variant="primary" type="submit">
                                ADD
                            </Button>
                        </Form>
                    </center>
                )}
                <br/>
            </div>
        )
    }
}