import React, { useEffect, useState } from "react";
import { useSearchParams, Link, Navigate } from 'react-router-dom';
import KitIcon from '../Assets/ic_kit.png'
import Select from 'react-select'
import Form from 'react-bootstrap/Form';
import Header from '../Elements/Header.tsx';
import DatePicker from "react-datepicker";
import Button from 'react-bootstrap/Button';
import {request} from '../Helpers/axios_helper.js';
import {TagStrings} from "../Elements/TagStrings.tsx";
import EPCTable from "../Elements/EPCTable.tsx";
import Stack from 'react-bootstrap/Stack';
import "../Styles/KitMaterial.css"
import "react-datepicker/dist/react-datepicker.css";

export class KitMaterial extends React.Component {
    kitData
    kitID
    materials
    backLink
    keyCount = 0

    constructor(props) {
        super(props);
        this.state = {loading: true, error: null, errorMsg: null};
    }

    onGetMaterials = () =>{
        request(
            "POST",
            "/getKit",
            {
                kitID: parseInt(this.kitID)
            }).then(
            (response) => {
                this.kitData = response.data
                this.setState({loading:false})
            }).catch(
            (error) => {
                console.log(error)
                this.setState({
                    error:true,
                    errorMsg:error.response.data["message"]});
                window.scrollTo(0, 0)
            }
        );
    }

    onDeleteMaterial = (materialID) => {
        console.log(materialID);
        request(
            "POST",
            "/deleteKitMaterial",
            {
                kitMaterialID: parseInt(materialID)
            }).then(
            (response) => {
                this.onGetMaterials()
                this.forceUpdate()
            }).catch(
            (error) => {
                console.log(error)
                this.setState({
                    error:true,
                    errorMsg:error.response.data["message"]});
                window.scrollTo(0, 0)
            }
        );
    }

    render(){
        let actuallyThis = this
        let { loading, error, errorMsg } = this.state;

        function UniqueKey(){
            actuallyThis.keyCount += 1
            return actuallyThis.keyCount
        }

        function GetKitData(){
            const [searchParams, setSearchParams] = useSearchParams();
            actuallyThis.kitID = searchParams.get("kit")
            actuallyThis.backLink = searchParams.get("back")
            actuallyThis.onGetMaterials();
        }

        function KitEPCTable(){
            return (<Stack className="kit-material-item">
                <EPCTable epc={actuallyThis.kitData.epc} />

                <Link className="ms-auto kit-material-add-btn-link" to={"/addKitMaterial?kit="+actuallyThis.kitID}>
                    <Button className="kit-material-add-btn" 
                            variant="success">
                                Add Material
                    </Button>
                </Link>
                </Stack>)
        }

        function KitMaterials(){
            const body = []

            for (let i = 0; i < actuallyThis.kitData.materials.length; i++) {
                const material = actuallyThis.kitData.materials[i];
                body.push(<Stack key={"key-"+UniqueKey()} className="kit-material-item">
                            <EPCTable epc={material.epc}/>
                            <Stack className="kit-materal-extra-stack" direction="horizontal" gap={5}>
                                <div className=""><b>LotNum:</b> {material.lotNum}</div>
                                <Button className="kit-material-delete-btn ms-auto" 
                                        onClick={() => actuallyThis.onDeleteMaterial(material.id)}
                                        variant="danger">
                                            Delete Material
                                </Button>
                            </Stack>
                          </Stack>);
            }

            return body;
        }

        return(
            <div>
                {!loading && (
                    <Header backLink={actuallyThis.backLink} />
                )}

                {loading && (
                    <GetKitData />
                )}

                {!loading && (
                    <center>
                    <div id="kit-material-container">
                        <KitEPCTable />
                        <hr id="kit-material-hr" />
                        <KitMaterials />
                        <br />
                    </div>
                    </center>
                )}

            </div>
        )
    }
}