import React from "react"
import CompanyIcon from '../Assets/ic_company.png'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Header from '../Elements/Header.tsx';
import {request} from '../Helpers/axios_helper';
import {Navigate} from "react-router-dom";
import "../Styles/AddCompany.css"

export class AddCompany extends React.Component {
    state = {complete: false, error: null, errorMsg: null};

    companyName = ""
    companyAddress = ""

    street = ""
    city = ""
    zip = ""
    state = ""
    country = ""

    onSetError = (errorMessage) => {
        this.setState({
            error:true,
            errorMsg:errorMessage});
        window.scrollTo(0, 0)
    }

    onChangeHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value.trim();

        switch(name){
            case 'name':
                this.companyName = value;
                break;
            case 'street':
                this.street = value;
                break;
            case 'city':
                this.city = value;
                break;
            case 'zip':
                this.zip = value;
                break;
            case 'state':
                this.state = value;
                break;
            case 'country':
                this.country = value;
                break;
        }

        this.companyAddress = this.street+", "+this.city+", "+this.state+", "+this.zip+", "+this.country;
    };
    
    onAddCompany = (e) => {
        e.preventDefault();

        if(this.companyName.length < 3){
            this.onSetError("Invalid company name")
            return;
        }

        if(this.street.length < 3){
            this.onSetError("Invalid street")
            return;
        }

        if(this.city.length < 3){
            this.onSetError("Invalid city")
            return;
        }

        if(this.state.length < 3){
            this.onSetError("Invalid province/state")
            return;
        }

        if(this.zip.length < 3){
            this.onSetError("Invalid postal/zip code")
            return;
        }

        if(this.country.length < 3){
            this.onSetError("Invalid country")
            return;
        }

        request(
            "POST",
            "/createCompany",
            {
                name: this.companyName,
                address: this.companyAddress
            }).then(
            (response) => {
                this.setState({
                    complete:true});
            }).catch(
            (error) => {
                console.log(error)
                this.setState({
                    error:true,
                    errorMsg:error.response.data["message"]});
                window.scrollTo(0, 0)
            }
        );
    }

    render() {
        let { complete, error, errorMsg } = this.state;
        return(
            <div>
                <Header backLink="companies" />

                {complete && (
                    <Navigate to="/companies" replace={true} />
                )}

                <center>
                <Form id="add-company-form" onSubmit={this.onAddCompany}>

                    <center>
                        <img id="add-company-logo" src={CompanyIcon} alt="logo"/>
                    </center>

                    <hr className="form-hr"></hr>

                    {error && 
                        <div id="error-alert" class="alert alert-danger mx-auto" role="alert">
                        <center><b>{errorMsg}</b></center>
                        </div>}

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="add-company-form-label">Name</Form.Label>
                        <Form.Control name="name" className="add-company-form-input" type="text" onChange={this.onChangeHandler} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="add-company-form-label">Street</Form.Label>
                        <Form.Control name="street" className="add-company-form-input" type="text" onChange={this.onChangeHandler} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="add-company-form-label">City</Form.Label>
                        <Form.Control name="city" className="add-company-form-input" type="text" onChange={this.onChangeHandler} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="add-company-form-label">Province/State</Form.Label>
                        <Form.Control name="state" className="add-company-form-input" type="text" onChange={this.onChangeHandler} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="add-company-form-label">Postal/Zip code</Form.Label>
                        <Form.Control name="zip" className="add-company-form-input" type="text" onChange={this.onChangeHandler} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="add-company-form-label">Country</Form.Label>
                        <Form.Control name="country" className="add-company-form-input" type="text" onChange={this.onChangeHandler} />
                    </Form.Group>

                    <center>
                        <Button id="add-company-btn" variant="primary" type="submit">
                        ADD
                        </Button>
                    </center>

                </Form>
                <br />
                </center>
            </div>
        )
    }
}