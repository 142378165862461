import React from 'react'
import {request} from '../Helpers/axios_helper';
import KitIcon from '../Assets/kit_icon_alt.png'
import MaterialIcon from '../Assets/material_icon_alt.png'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import '../Styles/EPCTable.css'

export default class EPCTable extends React.Component {
    state = {loading: true, error: null, errorMsg: null};
    epc
    epcData
    icon = true

    constructor(props) {
        super(props);
        this.epc = props.epc
        if(props.icon != null){
            this.icon = props.icon
        }

        this.onInitCache()
        if(this.epc in window.epcTableCache){
            this.epcData = window.epcTableCache[this.epc];
            this.state.loading = false
            return
        }

        request(
            "POST",
            "/decodeEpc",
            {
                epc: this.epc,
                lang: "en"
            }).then(
            (response) => {
                this.epcData = response.data
                this.onAddCache();
                this.setState({
                    loading:false});
            }).catch(
            (error) => {
                console.log(error)
                this.onSetError(error.response.data["message"])
            }
        )
    }

    onInitCache = () => {
        if(window.epcTableCache == null){
            window.epcTableCache = {};
        }
    }

    onAddCache = () => {
        window.epcTableCache[this.epc] = this.epcData;
    }

    private writeEPC = (epc) => {
        if (window.AndroidWriteEPC){
            window.AndroidWriteEPC.write(epc);
        }
    };

    onSetError = (errorMessage) => {
        this.setState({
            error:true,
            errorMsg:errorMessage});
    }

    getEpcWriteBtn = () => {
        return <Button 
                className="epc-table-write-btn" 
                variant="primary"
                onClick={() => this.writeEPC(this.epc)}>
                    <b>WRITE</b>
                </Button>
    }

    getTagTable = () => {
        switch(this.epcData.type){
            case 0:
                return this.getTag0Table();
            case 1:
                return this.getTag1Table();
            case 2:
                return this.getTag2Table();
        }
    }

    getTag0Table = () => {
        var tableBody : React.JSX.Element[] = []

        //Icon
        if(this.icon == true){
            tableBody.push(
                <tr>
                    <td rowSpan={8} className='epc-table-td epc-table-icon-td'> <center><img className="epc-table-icon" src={KitIcon} /></center></td>
                </tr>
            );
        }

        //Title
        tableBody.push(
            <tr>
                <td colSpan={2} className='epc-table-td epc-table-title'>{this.epcData.values.title}</td>
            </tr>
        );

        //Subtitle
        tableBody.push(
            <tr>
                <td colSpan={2} className='epc-table-td epc-table-title'>{this.epcData.values.subtitle}</td>
            </tr>
        );

        //Row 1
        tableBody.push(
            <tr>
                <td className='epc-table-td'><div className='epc-table-label'><b>Tag ID: </b>{this.epcData.epc.slice(0,2)+"-"+this.epcData.values.kit_id}</div></td>
                <td className='epc-table-td'><div className='epc-table-label'><b>Top Cover: </b>{this.epcData.values.top_cover}"</div></td>
            </tr>
        );

        //Row 2
        tableBody.push(
            <tr>
                <td className='epc-table-td'><div className='epc-table-label'><b>Manufactured: </b>{this.epcData.values.date}</div></td>
                <td className='epc-table-td'><div className='epc-table-label'><b>Bottom Cover: </b>{this.epcData.values.bottom_cover}"</div></td>
            </tr>
        );

        //Row 3
        tableBody.push(
            <tr>
                <td className='epc-table-td'><div className='epc-table-label'><b>Expiry: </b>{this.epcData.values.expiry}</div></td>
                <td className='epc-table-td'><div className='epc-table-label'><b>Width: </b>{this.epcData.values.width}"</div></td>
            </tr>
        );

        //Row 4
        tableBody.push(
            <tr>
                <td className='epc-table-td'><div className='epc-table-label'><b>Belt Rating: </b>{this.epcData.values.belt_rating}</div></td>
                <td className='epc-table-td'><div>&nbsp;</div></td>
            </tr>
        );

        //EPC
        tableBody.push(
            <tr>
                <td colSpan={2} className='epc-table-td epc-table-epc-label'>{this.epcData.epc}</td>
            </tr>
        );

        //Write button
        if (window.AndroidWriteEPC){
            tableBody.push(
                <tr>
                    <td colSpan={3}><this.getEpcWriteBtn/></td>
                </tr>
            );
        }

        return <div className='epc-table-container'>
        <Table className='epc-table'>
            <tbody>
                {tableBody}
                </tbody>
            </Table>
        </div>
    }

    getTag1Table = () => {
        var tableBody : React.JSX.Element[] = []

        //Icon
        if(this.icon == true){
            tableBody.push(
                <tr>
                    <td rowSpan={8} className='epc-table-td epc-table-icon-td'> <center><img className="epc-table-icon" src={MaterialIcon} /></center></td>
                </tr>
            );
        }

        //Title
        tableBody.push(
            <tr>
                <td colSpan={2} className='epc-table-td epc-table-title'>{this.epcData.values.material_num}</td>
            </tr>
        );

        //Subtitle
        tableBody.push(
            <tr>
                <td colSpan={2} className='epc-table-td epc-table-title'>{this.epcData.values.material_desc}</td>
            </tr>
        );

        //Row 1
        tableBody.push(
            <tr>
                <td className='epc-table-td'><div className='epc-table-label'><b>Tag ID: </b>{this.epcData.epc.slice(0,2)+"-"+this.epcData.tag_values.material_num+"-"+this.epcData.values.material_id}</div></td>
            </tr>
        );

        //Row 2
        tableBody.push(
            <tr>
                <td className='epc-table-td'><div className='epc-table-label'><b>Kit ID: </b>{"00-"+this.epcData.values.kit_id}</div></td>
            </tr>
        );

        //Row 3
        tableBody.push(
            <tr>
                <td className='epc-table-td'><div className='epc-table-label'><b>Manufactured: </b>{this.epcData.values.date}</div></td>
            </tr>
        );

        //Row 4
        tableBody.push(
            <tr>
                <td className='epc-table-td'><div className='epc-table-label'><b>Expiry: </b>{this.epcData.values.expiry}</div></td>
                <td className='epc-table-td'><div>&nbsp;</div></td>
            </tr>
        );

        //EPC
        tableBody.push(
            <tr>
                <td colSpan={2} className='epc-table-td epc-table-epc-label'>{this.epcData.epc}</td>
            </tr>
        );

        //Write button
        if (window.AndroidWriteEPC){
            tableBody.push(
                <tr>
                    <td colSpan={3}><this.getEpcWriteBtn/></td>
                </tr>
            );
        }

        return <div className='epc-table-container'>
            <Table className='epc-table'>
                <tbody>
                    {tableBody}
                </tbody>
            </Table>
        </div>
    }

    getTag2Table = () => {
        var tableBody : React.JSX.Element[] = []
        var embeddedData = {}
        var thirdPartyData = JSON.parse(atob(this.epcData.data))

        //Icon
        if(this.icon){
            tableBody.push(
            <tr>
                <td rowSpan={6} className='epc-table-td epc-table-icon-td'> <center><img className="epc-table-icon" src={MaterialIcon} /></center></td>
            </tr>);
        }

        //Title
        tableBody.push(<tr>
            <td colSpan={2} className='epc-table-td epc-table-title'>{thirdPartyData.name}</td>
        </tr>);

        //Other data
        embeddedData = {
            "Tag ID":this.epcData.epc.slice(0,2)+"-"+this.epcData.values.kit_id,
            "Manufactured":this.epcData.values.date,
            "Expiry":this.epcData.values.expiry
        }

        var completeData = Object.assign({}, embeddedData, thirdPartyData);
        var loop = 0;
        var tds : React.JSX.Element[] = []
        for (const [key, value] of Object.entries(completeData)) {
            if(key == "name"){
                continue;
            }

            tds.push(<td className='epc-table-td'><div className='epc-table-label'><b>{this.captialize(key)}: </b>{value}</div></td>)

            if(loop%2 == 1){
                tableBody.push(<tr>{tds}</tr>)
                tds = []
            }

            loop++
        }

        if(tds.length != 0){
            tableBody.push(<tr>{tds}</tr>)
        }
  
        //EPC
        tableBody.push(<tr>
            <td colSpan={2} className='epc-table-td epc-table-epc-label'>{this.epcData.epc}</td>
        </tr>);

        //Write button
        if (window.AndroidWriteEPC){
            tableBody.push(
                <tr>
                    <td colSpan={3}><this.getEpcWriteBtn/></td>
                </tr>
            );
        }

        return <div className='epc-table-container'>
        <Table className='epc-table'>
            <tbody>
                {tableBody}
                </tbody>
            </Table>
        </div>
    }

    captialize = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    render() {
        let { loading, error, errorMsg } = this.state;

        return (
            <div>
                {loading && !error && (
                    <div id="loading-div">Loading...</div>
                )}

                {error && (
                    <div id="error-alert" className="alert alert-danger mx-auto" role="alert">
                    <center><b>{errorMsg}</b></center>
                    </div>
                )}

                {!loading && (
                    <div>
                        <this.getTagTable />
                    </div>
                )}
            </div>
        )
    }
}