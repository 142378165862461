import React from "react"
import { useSearchParams, Link, Navigate } from 'react-router-dom';
import ArrayTable from "../Elements/ArrayTable.tsx";
import Header from '../Elements/Header.tsx';
import {TagStrings} from "../Elements/TagStrings.tsx";
import {request} from '../Helpers/axios_helper.js';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import TagIcon from '../Assets/ic_tag.png'
import "../Styles/EditTagStrings.css"

export class EditTagStrings extends React.Component {
    tagStringID
    tagStringData

    constructor(props) {
        super(props);
        this.state = {loading: true,  error: null, errorMsg: null}
    }

    onGetTagStrings = () => {
        request(
            "POST",
            "/tagStrings",
            {
                lang: "en",
                companyKey: "VU8UC6" //Almex stoney creek
            }).then(
            (response) => {
                this.tagStringData = response.data
                this.setState({loading:false});
            }).catch(
            (error) => {
                console.log(error)
            }
        ); 
    }

    render() {
        let actuallyThis = this
        let {loading, error, errorMsg } = this.state

        function GetID(){
            const [searchParams, setSearchParams] = useSearchParams();
            actuallyThis.tagStringID = searchParams.get("ts")
            actuallyThis.onGetTagStrings()
        }

        function GetArrayTable(){
            switch(actuallyThis.tagStringID){
                case "1":
                    return <ArrayTable array={actuallyThis.tagStringData.belt_ratings} />
                case "2":
                    return <ArrayTable array={actuallyThis.tagStringData.cover_sizes} />
                case "3":
                    return <ArrayTable array={actuallyThis.tagStringData.kits} />
                case "4":
                    return <ArrayTable array={actuallyThis.tagStringData.materials} />
            }
        }

        function GetTitle(){
            switch(actuallyThis.tagStringID){
                case "1":
                    return <span id="companies-title" >Belt Ratings</span>
                case "2":
                    return <span id="companies-title" >Cover Sizes</span>
                case "3":
                    return <span id="companies-title" >Kits</span>
                case "4":
                    return <span id="companies-title" >Materials</span>
            }
        }

        return(
            <div>
                <Header backLink="tagStringDash" />

                {loading && (
                    <GetID />
                )}

                {!loading && (
                    <div id="content">
                        <center>
                            <img id="companies-icon" src={TagIcon} />
                            <br />
                            <GetTitle />
                        </center>
                        <GetArrayTable />
                        <Stack>
                            <Link to={"/addTagString?ts="+actuallyThis.tagStringID} className="p-2 ms-auto">
                                <Button id="add-new-company-btn" className="ms-auto" variant="primary"><b>Add New</b></Button>
                            </Link>
                        </Stack>
                    </div>
                )}

                <br />
            </div>
        )
    }
}